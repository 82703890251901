<template>
  <div class="filters-container">
    <div class="filter-item">
      <InputText
        id="search-filter"
        :modelValue="filters.search"
        @input="updateFilter('search', $event.target.value)"
        placeholder="Filter..."
        class="w-full"
        :autofocus="props.isModal && !isAnyFilterActive"
      />
    </div>

    <div class="filter-item">
      <label class="filter-heading">Attachments</label>
      <div class="flex flex-wrap gap-1">
        <span
          v-for="toggle in toggleOptions"
          :key="toggle.name"
          class="toggle-button"
        >
          <Button
            :label="toggle.label"
            :class="toggleClass(filters.toggles[toggle.name])"
            @click="showAttachmentsPopover($event, toggle.name)"
            class="w-full md:w-auto"
            ref="attachmentButtons"
          />
        </span>
      </div>

      <Popover ref="attachmentsPopover" :dismissable="true">
        <div class="attachments-options">
          <button
            class="attachment-option"
            @click="selectAttachmentOption('has')"
            :autofocus="
              filters.toggles[attachmentsPanelName] === true ||
              !isAttachmentValueSelected
            "
          >
            Has
          </button>
          <button
            class="attachment-option"
            @click="selectAttachmentOption('does_not_have')"
            :autofocus="filters.toggles[attachmentsPanelName] === false"
          >
            Does Not Have
          </button>
          <button
            v-if="isAttachmentValueSelected"
            class="attachment-option"
            @click="clearAttachmentOption"
            :autofocus="filters.toggles[attachmentsPanelName] === null"
          >
            Clear
          </button>
        </div>
      </Popover>
    </div>

    <div class="filter-item">
      <label for="labels-filter" class="filter-heading">Labels</label>
      <MultiSelect
        id="labels-filter"
        :model-value="filters.labels"
        @change="updateFilter('labels', $event.value)"
        :options="labelOptions"
        placeholder="Select labels"
        optionLabel="label"
        optionValue="value"
        class="w-full"
        filter
        showClear
      />
    </div>

    <div class="filter-item">
      <label for="owner-filter" class="filter-heading">Owner</label>
      <Select
        id="owner-filter"
        :model-value="filters.owner"
        @change="updateFilter('owner', $event.value)"
        :options="ownerOptions"
        showClear
        placeholder="Select owner"
        optionLabel="label"
        optionValue="value"
        class="w-full"
      />
    </div>

    <div class="filter-item">
      <label for="date-range-filter" class="filter-heading">Date Created</label>
      <DatePicker
        id="date-range-filter"
        :modelValue="filters.dateRange"
        @update:modelValue="(val) => updateFilter('dateRange', val)"
        selectionMode="range"
        :manualInput="false"
        placeholder="Select date range"
        class="w-full"
        showButtonBar
      />
    </div>

    <div class="filter-item">
      <label for="colors-filter" class="filter-heading">Colors</label>
      <div class="color-filter-container">
        <!-- Color Swatches for Filters -->
        <button
          v-for="color in colorOptions"
          :key="color.name"
          :style="{ backgroundColor: color.hex }"
          class="color-swatch"
          @click="toggleColorFilter(color)"
          v-tooltip.top="color.name"
        >
          <CheckIcon v-if="filters.colors.includes(color.name)" />
        </button>
      </div>
    </div>

    <div class="filter-item mt-4">
      <Button
        :disabled="!isAnyFilterActive"
        :outlined="!isAnyFilterActive"
        severity="info"
        class="w-full"
        label="Clear Filters"
        @click="clearFilters"
      />
    </div>
  </div>
</template>
<script setup>
import { computed, ref, nextTick, defineProps } from "vue";
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import Button from "primevue/button";
import MultiSelect from "primevue/multiselect";
import DatePicker from "primevue/datepicker";
import Popover from "primevue/popover";
import CheckIcon from "./NoteComp/CheckIcon.vue";

import { useFilters } from "../hooks/useFilters";

const props = defineProps({
  isModal: Boolean,
});

const {
  filters,
  toggleOptions,
  labelOptions,
  ownerOptions,
  colorOptions,
  toggleClass,
  isAnyFilterActive,
  clearFilters,
  updateFilter,
} = useFilters();

const attachmentsPopover = ref(null);
const attachmentsPanelName = ref(null);
const lastFocusedElement = ref(null); // To store the last focused element

const showAttachmentsPopover = (event, name) => {
  lastFocusedElement.value = event.currentTarget; // Store the element that opened the Popover
  attachmentsPanelName.value = name;
  attachmentsPopover.value.hide();
  nextTick(() => {
    attachmentsPopover.value.show(event);
  });
};

const isAttachmentValueSelected = computed(() =>
  attachmentsPanelName.value
    ? [true, false].indexOf(
        filters.value?.toggles[attachmentsPanelName.value]
      ) > -1
    : false
);

const selectAttachmentOption = (option) => {
  const toggles = { ...filters.value.toggles };
  if (option === "has") {
    toggles[attachmentsPanelName.value] = true;
    updateFilter("toggles", toggles);
  } else if (option === "does_not_have") {
    toggles[attachmentsPanelName.value] = false;
    updateFilter("toggles", toggles);
  }
  closeAttachmentsPopover();
};

const clearAttachmentOption = () => {
  const toggles = { ...filters.value.toggles };
  toggles[attachmentsPanelName.value] = null;
  updateFilter("toggles", toggles);
  closeAttachmentsPopover();
};

const closeAttachmentsPopover = () => {
  attachmentsPopover.value.hide();
  nextTick(() => {
    lastFocusedElement.value?.focus(); // Return focus to the element that opened the Popover
  });
};

const toggleColorFilter = (color) => {
  let selectedColors = [...filters.value.colors];

  const colorIndex = selectedColors.indexOf(color.name);

  if (colorIndex !== -1) {
    // If the color is already selected, remove it
    selectedColors.splice(colorIndex, 1);
  } else {
    // If the color is not selected, add it
    selectedColors.push(color.name);
  }

  updateFilter("colors", selectedColors);
};
</script>

<style scoped>
.filters-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.9rem;
  text-align: left;
  color: var(--corp-text-color);
}

.filter-item {
  margin-bottom: 0.5rem;
}

.filter-heading {
  font-weight: bold;
  display: block;
  margin-bottom: 0.25rem;
  margin-top: 0.5rem;
  font-size: 0.75rem;
  color: var(--corp-text-color);
}

.color-filter-container {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: #000;
}

.check-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon svg {
  width: 8px;
  height: 8px;
}

.toggle-button button {
  font-size: 0.9rem;
  padding: 0.25rem 0.45rem;
}
.color-swatch,
.no-color-swatch {
  width: 30px;
  height: 30px;
  border-radius: 50%;

  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.no-color-swatch {
  background: #fff;
  border: 1px dashed #ccc;
  color: #ccc;
}

.check-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.check-icon svg {
  width: 8px;
  height: 8px;
}

.filters-container .p-inputtext,
.filters-container .p-dropdown,
.filters-container .p-multiselect,
.filters-container .p-calendar {
  width: 100%;
}
.attachment-option {
  display: block;
  width: 100%;
  text-align: left;
  border: 0;
  background: none;
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
  outline: none;
}
.attachment-option:focus {
  /* border-bottom-color: var(--corp-primary-color); */
  background: var(--corp-select-option-focus-background);
  color: var(--corp-select-option-focus-color);
}

.options-list li.selected {
  background: var(--corp-select-option-selected-background);
  color: var(--corp-select-option-selected-color);
}

.options-list li.highlighted {
  background: var(--corp-select-option-focus-background);
  color: var(--corp-select-option-focus-color);
}

.options-list li.selected.highlighted {
  background: var(--corp-select-option-selected-focus-background);
  color: var(--corp-select-option-selected-focus-color);
}
.options-list li:hover {
  background: var(--corp-select-option-focus-background);
  color: var(--corp-select-option-focus-color);
}
</style>
