// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { historyStack, isBackNavigation } from "./historyStore"; // Adjust path as needed
import HomeWrap from "../pages/HomeWrap.vue";
import PreviewPage from "../pages/PreviewPage.vue";
import LoginPage from "../pages/LoginPage.vue";
import RegistrationPage from "../pages/RegistrationPage.vue";
import ForgotPasswordPage from "../pages/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/ResetPasswordPage.vue";
import ChangePasswordPage from "../pages/ChangePasswordPage.vue";
import ProfilePage from "../pages/ProfilePage.vue";
import TermsOfUsePage from "../pages/TermsOfUsePage.vue";
import PrivacyPage from "../pages/PrivacyPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import ContactPage from "../pages/ContactPage.vue";
import VerifyEmail from "../pages/VerifyEmail.vue";

import store from "../store";

const routes = [
  {
    path: "/note/:id",
    component: HomeWrap,
    meta: { requiresAuth: true, show: true },
  },
  {
    path: "/note/:id/details",
    component: HomeWrap,
    meta: { requiresAuth: true, show: true },
  },
  {
    path: "/note/:id/comments",
    component: HomeWrap,
    meta: { requiresAuth: true, show: true },
  },
  {
    path: "/note/:id/activity",
    component: HomeWrap,
    meta: { requiresAuth: true, show: true },
  },
  {
    path: "/note/:id/:token",
    component: PreviewPage,
    meta: { requiresAuth: false, show: true },
  },
  {
    path: "/preview/:id",
    component: HomeWrap,
    meta: { requiresAuth: true, preview: true },
  },
  { path: "/", component: HomeWrap, meta: {} },
  { path: "/login", component: LoginPage },
  { path: "/contacts", component: ContactPage, meta: { requiresAuth: true } },
  { path: "/profile", component: ProfilePage, meta: { requiresAuth: true } },
  {
    path: "/profile/edit",
    component: ProfilePage,
    meta: { requiresAuth: true, edit: true },
  },
  { path: "/register", component: RegistrationPage },
  { path: "/forgot-password", component: ForgotPasswordPage },
  {
    path: "/change-password",
    component: ChangePasswordPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password/:token",
    component: ResetPasswordPage,
    meta: { requiresAuth: true },
  },
  { path: "/admin", component: AdminPage, meta: { requiresAdmin: true } },
  // { path: "/public", component: InvitationPage },
  {
    path: "/verify-email",
    component: VerifyEmail,
  },
  {
    path: "/terms-of-service",
    component: TermsOfUsePage,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPage,
  },
  // {
  //   path: "/editor",
  //   component: Editor,
  // },
];

const router = createRouter({
  linkActiveClass: "active-nav",
  linkExactActiveClass: "exact-active-nav",
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log("store", store);
  const user = store.state.user; // Assuming you have user info in a global store
  if (to.meta.requiresAuth && !user) {
    next(`/login?return_url=${to.path}`);
  } else if (to.meta.requiresAdmin && (!user || user.roleId !== 1)) {
    next(`/login?return_url=${to.path}`);
  } else {
    // Check if the navigation is backward
    console.log(
      "beforeEach",
      historyStack.value[historyStack.value.length - 2],
      to.fullPath
    );
    if (
      historyStack.value.length > 1 &&
      historyStack.value[historyStack.value.length - 2] === to.fullPath
    ) {
      isBackNavigation.value = true;
    } else {
      isBackNavigation.value = false;
    }
    next();
  }
});

// After each navigation, update the history stack
router.afterEach((to) => {
  console.log(
    "afterEach",
    to.fullPath,
    isBackNavigation.value,
    to.fullPath === "/"
  );
  if (isBackNavigation.value) {
    if (to.fullPath === "/") {
      historyStack.value = ["/"];
    } else {
      // Remove the last route when navigating back
      historyStack.value.pop();
    }
  } else {
    // Add the new route to the history stack
    if (historyStack.value[historyStack.value.length - 1] !== to.fullPath) {
      historyStack.value.push(to.fullPath);
    }
  }
  console.log("historyStack.value", historyStack.value);
});

export default router;
