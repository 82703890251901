import WorkerPool from "../utils/workerPool"; // Adjust path as needed
import { ref, computed } from "vue";
import api from "../utils/api";
import { generateRandomKey } from "../utils/helpers";
import axios from "axios";
import heic2any from "heic2any";

const poolSize = Math.min(4, navigator.hardwareConcurrency || 2);
const workerPool = new WorkerPool(
  "/workers/imageProcessorWorker.js",
  poolSize // Number of concurrent workers
);

const uploadProgress = ref({});
const uploadStatus = ref({});
const uploadingFiles = ref({});
const abortControllers = ref({});
const chunkSize = 5 * 1024 * 1024; // 5MB

export function useUploadStore() {
  const setPending = (noteId = "new", file) => {
    if (!uploadingFiles.value[noteId]) {
      uploadingFiles.value[noteId] = [];
    }
    const tmpFileId = generateRandomKey();
    // uploadStatus.value[`${noteId}-${tmpFileId}`] = "processing";
    uploadingFiles.value[noteId].push({
      id: tmpFileId,
      name: file.name,
    });
    return tmpFileId;
  };
  const removePending = (noteId = "new", fileId) => {
    delete uploadStatus.value[`${noteId}-${fileId}`];
    uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
      (file) => file.id !== fileId
    );
  };
  // Convert HEIC to JPEG using heic2any
  const convertHeicToJpg = async (file) => {
    if (!file.type.startsWith("image/")) return file;
    if (file.type !== "image/heic" && file.type !== "image/heif") return file;
    console.log("convertHeicToJpg");
    const blob = await heic2any({ blob: file, toType: "image/jpeg" });
    return new File([blob], file.name.replace(/\.[^/.]+$/, ".jpg"), {
      type: "image/jpeg",
    });
  };

  // Compress image using the worker pool
  const compressImageWithWorker = (file) => {
    console.log("compressImageWithWorker");
    return workerPool.runTask({ file, action: "compressImage" });
  };

  // Unified function to convert and compress
  const prepareFileForUpload = async (file) => {
    console.log("prepareFileForUpload");
    // Convert HEIC if necessary
    let preparedFile = await convertHeicToJpg(file);

    if (file.type.startsWith("image/")) {
      // Compress image
      return await compressImageWithWorker(preparedFile);
    } else {
      return preparedFile;
    }
  };

  const initiateUpload = async (file) => {
    console.log("initiateUpload");
    const response = await api.post("/api/files/initiate-upload", {
      fileName: file.name,
      fileType: file.type,
    });
    return response.data;
  };

  const uploadFile = async (file, noteId = "new", tmpFileId) => {
    try {
      const preparedFile = await prepareFileForUpload(file); // Convert and compress
      const { uploadId, fileId, fileName } = await initiateUpload(
        preparedFile,
        noteId
      );

      // remove temp
      delete uploadStatus.value[`${noteId}-${tmpFileId}`];
      // add real file mappings
      uploadStatus.value[`${noteId}-${fileId}`] = "uploading";
      // remove temp and add new
      uploadingFiles.value[noteId] = [
        ...uploadingFiles.value[noteId].filter((f) => f.id !== tmpFileId),
        {
          id: fileId,
          name: fileName,
          filePath: fileName,
        },
      ];

      const parts = [];
      for (let i = 0; i < preparedFile.size; i += chunkSize) {
        const partNumber = Math.floor(i / chunkSize) + 1;
        const part = await uploadChunk(
          preparedFile,
          partNumber,
          uploadId,
          i,
          i + chunkSize,
          noteId,
          fileId,
          fileName
        );
        parts.push(part);
      }

      const newFile = await completeUpload(fileName, uploadId, parts, fileId);

      delete uploadStatus.value[`${noteId}-${fileId}`];
      uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
        (file) => file.id !== fileId
      );
      return newFile.data;
    } catch (e) {
      console.log("error", e);
    }
  };

  const cancelUpload = (noteId = "new", fileId) => {
    const key = `${noteId}-${fileId}`;
    if (abortControllers.value[key]) {
      abortControllers.value[key].abort();
      delete abortControllers.value[key];

      uploadingFiles.value[noteId] = uploadingFiles.value[noteId].filter(
        (file) => file.id !== fileId
      );
    }
  };

  const getUploadingFilesByNoteId = (noteId = "new") =>
    computed(() => {
      return uploadingFiles.value[noteId] || [];
    });
  const uploadChunk = async (
    file,
    partNumber,
    uploadId,
    start,
    end,
    noteId,
    fileId,
    fileName,
    retries = 3
  ) => {
    try {
      console.log("upload chunk");
      const chunk = file.slice(start, end);
      const res = await api.get("/api/files/signed-url", {
        params: {
          fileName: fileName,
          fileType: file.type,
          partNumber,
          uploadId,
        },
      });

      const signedUrl = res.data.signedUrl;

      const response = await axios.put(signedUrl, chunk, {
        headers: { "Content-Type": file.type },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (!uploadProgress.value[noteId]) {
            uploadProgress.value[noteId] = {};
          }
          uploadProgress.value[noteId][fileId] = progress;
        },
      });
      const etag = response.headers.etag;
      return { ETag: etag, PartNumber: partNumber, Size: chunk.size };
      // return { ETag: response.headers.etag, PartNumber: start / chunkSize + 1 };
    } catch (error) {
      if (retries > 0) {
        console.warn(`Retrying chunk upload (remaining retries: ${retries})`);
        return uploadChunk(
          file,
          partNumber,
          uploadId,
          start,
          end,
          noteId,
          fileId,
          fileName,
          retries - 1
        );
      } else {
        console.error("Chunk upload failed after retries:", error);
        throw error;
      }
    }
  };
  // const uploadChunk = async (
  //   file,
  //   partNumber,
  //   uploadId,
  //   start,
  //   end,
  //   noteId,
  //   fileId,
  //   fileName
  // ) => {
  //   const chunk = file.slice(start, end);
  //   const response = await api.get("/api/files/signed-url", {
  //     params: {
  //       fileName: fileName,
  //       fileType: file.type,
  //       partNumber,
  //       uploadId,
  //     },
  //   });

  //   const signedUrl = response.data.signedUrl;
  //   const controller = new AbortController();
  //   abortControllers.value[`${noteId}-${fileId}`] = controller;

  //   const uploadResponse = await axios.put(signedUrl, chunk, {
  //     headers: {
  //       "Content-Type": file.type,
  //     },
  //     signal: controller.signal,
  //     onUploadProgress: (progressEvent) => {
  //       const progress = Math.round(
  //         (progressEvent.loaded * 100) / progressEvent.total
  //       );
  //       if (!uploadProgress.value[noteId]) {
  //         uploadProgress.value[noteId] = {};
  //       }
  //       uploadProgress.value[noteId][fileId] = progress;
  //     },
  //   });

  //   const etag = uploadResponse.headers.etag;
  //   return { ETag: etag, PartNumber: partNumber, Size: chunk.size };
  // };

  const completeUpload = async (fileName, uploadId, parts, fileId) => {
    console.log("complete upload");
    return await api.post("/api/files/complete-upload", {
      fileName,
      uploadId,
      parts,
      fileId,
    });
  };

  return {
    setPending,
    removePending,
    uploadFile,
    uploadProgress,
    uploadStatus,
    cancelUpload,
    getUploadingFilesByNoteId,
  };
}
