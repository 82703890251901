<!-- ResponsiblePersonPicker.vue -->
<template>
  <div class="py-4 mb-2 flex">
    <FloatLabel variant="on" class="flex-1">
      <AutoComplete
        ref="picker"
        v-model="selectedUser"
        :suggestions="userSuggestions"
        @complete="fetchSuggestions"
        placeholder=""
        optionLabel="name"
        forceSelection
        class="w-full"
        inputClass="w-full"
        inputId="on_label"
        completeOnFocus
        dropdown
        dropdownMode="blank"
      >
        <template #option="{ option }"
          ><UserAutoCompleteItem :user="option" />
        </template>
      </AutoComplete>
      <label for="on_label">Responsible user </label>
    </FloatLabel>
    <Button
      v-if="selectedUser"
      @click="selectedUser = null"
      class="ml-1 rounded-xl"
      size="small"
      >clear</Button
    >
    <!-- <Button
      v-if="selectedUser"
      label="Clear"
      text
      icon="pi pi-times"
      @click="clearUser"
    /> -->
  </div>
</template>

<script setup>
import { ref, defineProps, watch, defineEmits } from "vue";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import { useConfirm } from "primevue/useconfirm";
import FloatLabel from "primevue/floatlabel";
import { useUserStore } from "@/hooks/useUserStore";
import { useNoteStore } from "@/hooks/useNoteStore";
import UserAutoCompleteItem from "./UserAutoCompleteItem.vue";

const emit = defineEmits(["onUserSelect"]);
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  responsibleUserEmail: String,
  onUserSelect: Function,
  noteId: String,
});

const confirm = useConfirm();

const picker = ref();
const selectedUser = ref(props.responsibleUserEmail);
const userSuggestions = ref([]);

const { fetchUserContactSuggestions } = useUserStore();
const { fetchNoteById } = useNoteStore();

const note = ref();

const load = async () => {
  if (props.noteId) {
    note.value = await fetchNoteById(props.noteId, { lazy: true });
  }
};
load();
const fetchSuggestions = (event) => {
  userSuggestions.value = [];

  fetchUserContactSuggestions(event?.query).then((results) => {
    userSuggestions.value = results.filter((r) => r.id);
  });
};

// const clearUser = () => {
//   selectedUser.value = null;
//   emit("onUserSelect", null);
// };

watch(selectedUser, (newUser) => {
  if (newUser instanceof Object) {
    if (newUser.owner || newUser === props.responsibleUserEmail) {
      emit("onUserSelect", selectedUser.value);
      picker.value.hide();
      return;
    }
    if (!newUser.email) {
      // contact no email
      console.log(
        "(newUser?.email",
        newUser?.email,
        props.responsibleUserEmail
      );

      confirmPhone(
        () => {
          emit("onUserSelect", selectedUser.value);
          picker.value.hide();

          const shareUrlComplete = `https://storatic.com/note/${props.noteId}/${note.value.shareUrl}`;
          const phoneNumber = selectedUser.value.phone;
          const message = `I assigned you a task in Storatic ${shareUrlComplete}`;
          const smsUrl = `sms:${phoneNumber}?&body=${encodeURIComponent(
            message
          )}`;

          window.open(smsUrl, "_blank"); // Opens in a new tab
        },
        () => {
          selectedUser.value = props.responsibleUserEmail;
        }
      );
    } else {
      confirmAdd(
        () => {
          emit("onUserSelect", selectedUser.value);
          picker.value.hide();
        },
        () => {
          selectedUser.value = props.responsibleUserEmail;
        }
      );
    }
  } else {
    emit("onUserSelect", null);
  }
});

const confirmPhone = (accept, reject) => {
  confirm.require({
    message: `<p>This contact does not have an email.</p>
    <p>Assigning the task to this contact will change the note's public url mode to 'Edit' and prefill an SMS with the share link for you to review and send.</p>
    <p>Would you like to continue?</p>`,
    header: "Confirm Assignment and Share",
    // icon: "pi pi-user",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Yes, Continue",
    },
    accept,
    reject,
  });
};

const confirmAdd = (accept, reject) => {
  confirm.require({
    message: `<p>Assigning this contact to the task will also add the contact as an collaborator with "Edit" access of the note and notify them via email using the provided address.</p>
    <p>Would you like to continue?</p>`,
    header: "Confirm Assignment and Share",
    // icon: "pi pi-user",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Yes, Continue",
    },
    accept,
    reject,
  });
};
</script>
