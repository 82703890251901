import { ref } from "vue";

const isScrollable = (element) => {
  const style = window.getComputedStyle(element);
  return (
    style.overflow === "auto" ||
    style.overflow === "scroll" ||
    style.overflowY === "auto" ||
    style.overflowY === "scroll" ||
    style.overflowX === "auto" ||
    style.overflowX === "scroll"
  );
};

export function useSwipeHandler({
  direction = "up",
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
  feedbackElement, // Element to animate
  bufferTime = 100, // Time in ms to enforce the "at top" state
  scrollContainerSelector, // Selector for the scrollable container
}) {
  const allowedDirections = direction.split(",").map((dir) => dir.trim());
  const touchStartX = ref(0);
  const touchEndX = ref(0);
  const touchStartY = ref(0);
  const touchEndY = ref(0);
  let lastCleared = new Date().getTime();

  const isAtTopForBufferTime = () => {
    const scrollContainer = document.querySelector(scrollContainerSelector);
    if (!scrollContainer) return true;

    const top = scrollContainer.scrollTop;
    const now = new Date().getTime();

    if (top > 0) {
      lastCleared = 0; // Reset the timer if the container is scrolled
      return false;
    }

    if (lastCleared === 0) {
      lastCleared = now; // Start the timer when the container reaches the top
      return false;
    }

    return now - lastCleared >= bufferTime; // Return true only if buffer time is met
  };

  const handleTouchStart = (event) => {
    if (isScrollable(event.target)) {
      event.stopPropagation();
    }
    touchStartX.value = event.changedTouches[0].screenX;
    touchStartY.value = event.changedTouches[0].screenY;

    const feedbackElm = document.querySelector(feedbackElement);
    if (feedbackElm) {
      feedbackElm.style.transform = "";
      feedbackElm.style.opacity = "1";
    }
  };

  const handleTouchMove = (event) => {
    if (isScrollable(event.target)) {
      event.stopPropagation();
    }

    const currentX = event.changedTouches[0].screenX;
    const currentY = event.changedTouches[0].screenY;

    const deltaX = currentX - touchStartX.value;
    const deltaY = currentY - touchStartY.value;

    const feedbackElm = document.querySelector(feedbackElement);
    if (!feedbackElm || !isAtTopForBufferTime()) return;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      // Horizontal swipe
      if (allowedDirections.includes("left") && deltaX < 0) {
        feedbackElm.style.transform = `translateX(${deltaX}px)`;
        feedbackElm.style.opacity = `${1 - Math.abs(deltaX) / 300}`;
      } else if (allowedDirections.includes("right") && deltaX > 0) {
        feedbackElm.style.transform = `translateX(${deltaX}px)`;
        feedbackElm.style.opacity = `${1 - Math.abs(deltaX) / 300}`;
      }
    } else {
      // Vertical swipe
      if (allowedDirections.includes("up") && deltaY < 0) {
        feedbackElm.style.transform = `translateY(${deltaY}px)`;
        feedbackElm.style.opacity = `${1 - Math.abs(deltaY) / 300}`;
      } else if (allowedDirections.includes("down") && deltaY > 0) {
        feedbackElm.style.transform = `translateY(${deltaY}px)`;
        feedbackElm.style.opacity = `${1 - Math.abs(deltaY) / 300}`;
      }
    }
  };

  const handleTouchEnd = (event) => {
    if (isScrollable(event.target)) {
      event.stopPropagation();
    }
    touchEndX.value = event.changedTouches[0].screenX;
    touchEndY.value = event.changedTouches[0].screenY;

    const swipeThreshold = 150; // Minimum X distance to recognize a swipe
    const swipeYThreshold = 100; // Minimum Y distance to recognize a swipe
    const swipeXDistance = touchEndX.value - touchStartX.value;
    const swipeYDistance = touchEndY.value - touchStartY.value;

    const feedbackElm = document.querySelector(feedbackElement);
    if (feedbackElm) {
      feedbackElm.style.transform = "";
      feedbackElm.style.opacity = "1";
    }

    if (!isAtTopForBufferTime()) return;

    if (Math.abs(swipeXDistance) > Math.abs(swipeYDistance)) {
      // Horizontal swipe
      if (
        allowedDirections.includes("right") &&
        swipeXDistance > swipeThreshold
      ) {
        onSwipeRight && onSwipeRight();
      } else if (
        allowedDirections.includes("left") &&
        swipeXDistance < -swipeThreshold
      ) {
        onSwipeLeft && onSwipeLeft();
      }
    } else {
      // Vertical swipe
      if (
        allowedDirections.includes("down") &&
        swipeYDistance > swipeYThreshold
      ) {
        onSwipeDown && onSwipeDown();
      } else if (
        allowedDirections.includes("up") &&
        swipeYDistance < -swipeYThreshold
      ) {
        onSwipeUp && onSwipeUp();
      }
    }
  };

  return {
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
  };
}
