<template>
  <component
    severity="contrast"
    text
    :is="button ? Button : 'div'"
    v-tooltip.bottom="'Files'"
    @click="triggerFileInput"
    :rounded="rounded"
  >
    <i class="pi pi-image"></i>
    <input
      type="file"
      ref="fileInput"
      @change="handleFileChange"
      multiple
      hidden
    />
  </component>
</template>

<script setup>
import { ref, defineProps, toRefs } from "vue";
import { useUploadStore } from "../../hooks/useUploadStore";
import { useFileStore } from "../../hooks/useFileStore";
import Button from "primevue/button";
import { isMobile } from "../../utils/helpers";

const props = defineProps({
  noteId: String,
  uploaded: Function,
  added: Function,
  button: Boolean,
  rounded: Boolean,
});
const { noteId } = toRefs(props);
const fileInput = ref(null);
const { createNoteFile } = useFileStore();
const { uploadFile, setPending } = useUploadStore();

const triggerFileInput = () => {
  fileInput.value.click();
};

const MAX_CONCURRENT_UPLOADS = isMobile ? 1 : 3; // Dynamically adjustable
const uploadQueue = [];
let activeUploads = 0;

const handleFileChange = async (event) => {
  const files = Array.from(event.target.files);
  if (files.length) {
    files.forEach((file) => {
      uploadQueue.push(createUploadTask(file));
    });

    processQueue(); // Start processing the queue
  }
};

const createUploadTask = (file) => {
  const tmpFileId = setPending(noteId.value, file);
  return async () => {
    let newFiles = [];
    const newFile = await uploadFile(file, noteId.value, tmpFileId);
    if (noteId.value) {
      const res = await createNoteFile([newFile.id], noteId.value);
      // newFiles = [...newFiles, ...res];
      newFiles = [...res];
    } else {
      // newFiles = [...newFiles, newFile];
      newFiles = [newFile];
    }
    if (!noteId.value) {
      await props.uploaded(newFiles);
    }
    if (props.added) await props.added(newFiles);
    return;
  };
  // return async () => {
  //   const tmpFileId = setPending(noteId.value, file);
  //   try {
  //     const uploadedFile = await uploadFile(file, noteId.value, tmpFileId);
  //     if (noteId.value) {
  //       const res = await createNoteFile([uploadedFile.id], noteId.value);
  //       props.uploaded && props.uploaded(res);
  //       props.added && props.added(res);
  //     }
  //   } catch (error) {
  //     console.error(`Error uploading file ${file.name}:`, error);
  //   }
  // };
};

const processQueue = async () => {
  while (uploadQueue.length && activeUploads < MAX_CONCURRENT_UPLOADS) {
    const task = uploadQueue.shift();
    if (task) {
      activeUploads++;
      try {
        await task();
      } catch (error) {
        console.error("Error processing task:", error);
      } finally {
        activeUploads--;
        processQueue(); // Continue processing the next tasks
      }
    }
  }
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
