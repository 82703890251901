import { ref } from "vue";

let config = {
  laststep: ref(null),
  step: ref(0),
  isReady: ref(false),
  isShowing: ref(false),
  steps: [],
  wrapper: "body",
  maskClass: "tutorial",
  activeClass: "active-tutorial-step",
};

function useTutorial() {
  const setStep = () => {
    config.isReady.value = false;
    const lastStep = config.steps[config.laststep.value];
    const currentStep = config.steps[config.step.value];
    document
      .querySelector(`.${config.activeClass}`)
      ?.classList.remove(config.activeClass);
    if (lastStep) {
      const lastelm = document.querySelector(lastStep.selector);
      if (lastelm && lastStep.after) {
        lastStep.after(lastelm);
      }
    }
    if (currentStep) {
      const next = () => {
        const findElm = () => {
          const elm = document.querySelector(currentStep.selector);
          const hole = document.querySelector("#hole");
          if (elm) {
            const rect = elm.getBoundingClientRect();
            // Get the position and size of the target element
            // Update the hole position and size based on the target
            // Update the hole position and size to match the target element
            hole.setAttribute("x", rect.left);
            hole.setAttribute("y", rect.top);
            hole.setAttribute("width", rect.width);
            hole.setAttribute("height", rect.height);

            elm.classList.add(config.activeClass);
            if (currentStep.mount) {
              // need to let the render digest first
              setTimeout(() => {
                currentStep.mount(elm);
                config.isReady.value = true;
              }, 0);
            } else {
              setTimeout(() => {
                config.isReady.value = true;
              }, 0);
            }
          } else {
            // elm should be discoverable.. try again
            setTimeout(() => {
              findElm();
            }, 300);
          }
        };
        findElm();
      };
      if (currentStep.before) {
        // need to let the render digest first
        currentStep.before(next);
      } else {
        next();
      }
    } else {
      // no more steps
      show();
    }
  };

  const previous = () => {
    config.laststep.value = config.step.value;
    config.step.value = config.step.value - 1;
    setStep();
  };

  const next = () => {
    config.laststep.value = config.step.value;
    config.step.value = config.step.value + 1;
    setStep();
  };

  const goTo = (idx) => {
    config.laststep.value = config.step.value;
    config.step.value = idx;
    setStep();
  };

  const show = () => {
    // show it only if hiding
    if (!config.isShowing.value) {
      config.isShowing.value = true;
      document
        .getElementsByTagName(config.wrapper)[0]
        .classList.add(config.maskClass);
      goTo(0);
    } else if (config.isShowing.value) {
      // hide it only if showing
      config.isShowing.value = false;
      document
        .getElementsByTagName(config.wrapper)[0]
        .classList.remove(config.maskClass);

      document
        .querySelector(config.activeClass)
        ?.classList.remove(config.maskClass);
    }
  };
  const exit = () => {
    goTo(config.steps.length);
  };
  const isActive = (el) => {
    console.log("isActive", el);
    let ret = false;
    if (el) {
      ret = el.classList.contains(config.activeClass);
    }

    return ret;
  };

  const init = (props) => (config = { ...config, ...props });

  return {
    init,
    isReady: config.isReady,
    isActive,
    isShowing: config.isShowing,
    show,
    exit,
    previous,
    next,
    goTo,
    step: config.step,
  };
}

export default useTutorial;
