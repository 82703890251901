<template>
  <Header />
  <ConfirmDialog>
    <template #message="slotProps">
      <div class="flex">
        <i
          :class="slotProps.message.icon"
          class="!text-6xl text-primary-500"
        ></i>
        <div v-html="slotProps.message.message" />
      </div> </template
  ></ConfirmDialog>
  <Toast>
    <template #message="slotProps">
      <div class="flex-1 toast-bck" v-if="slotProps.reminder">
        <div class="font-semibold text-sm my-1 flex px-2">
          Reminder
          <div class="flex-1" />
          <LiveTimeFromNow :time="slotProps.message.start" />
        </div>
        <button
          :style="slotProps.message.color"
          @click="slotProps.message.onClick"
          class="w-full text-left border-none outline-0 p-2 border-round-md"
        >
          <div class="font-medium text-lg my-1">
            {{ slotProps.message.summary }}
          </div>
          <div class="font-medium text-sm my-1">
            {{ slotProps.message.detail }}
          </div>
          <span class="font-bold text-primary">View Details</span>
        </button>
      </div>
      <div v-else class="flex-1 toast-bck p-1">
        <div class="font-medium text-lg my-1">
          {{ slotProps.message.summary }}
        </div>
        <div class="font-medium text-sm my-1">
          {{ slotProps.message.detail }}
        </div>
      </div>
    </template></Toast
  >
  <div class="content">
    <Sidebar v-if="isAuthenticated" />
    <router-view v-if="!isAuthenticating" />
  </div>
  <div class="tutorial-mask">
    <svg width="0" height="0">
      <defs>
        <mask id="hole-mask">
          <!-- Default: Hide everything -->
          <rect x="0" y="0" width="100vw" height="100vh" fill="white" />
          <!-- Punch a hole (hide the target area) -->
          <rect id="hole" x="0" y="0" width="0" height="0" fill="black" />
        </mask>
      </defs>
    </svg>
  </div>

  <template v-if="tutorial.isShowing && tutorial.isReady">
    <Dialog
      v-for="(tutorialStep, index) in steps"
      :key="tutorialStep.selector"
      class="tutorial-modal"
      :pt="{
        mask: { class: 'tutorial-dialog-mask' },
      }"
      :draggable="false"
      :closable="false"
      :visible="index == step && tutorial.isShowing && tutorial.isReady"
      :position="tutorialStep.position"
      :style="tutorialStep.style"
    >
      <h3 v-if="tutorialStep.heading">{{ tutorialStep.heading }}</h3>
      <p>{{ tutorialStep.copy }}</p>
      <div class="flex">
        <Button
          text
          severity="secondary"
          size="small"
          label="Exit tutorial"
          v-if="steps.length - 1 !== step"
          @click="
            () => {
              tutorial.exit();
            }
          "
        />
        <div class="flex-1" />
        <Button
          :label="steps.length - 1 === step ? 'Exit Tutorial' : 'Next'"
          @click="
            () => {
              tutorial.next();
            }
          "
        />
      </div>
    </Dialog>
  </template>
</template>

<script setup>
import { watch, ref } from "vue";
import { useRouter } from "vue-router";
import { Capacitor } from "@capacitor/core";
import Header from "./components/HeaderComp.vue";
import Sidebar from "./components/SidebarComp.vue";
import Toast from "primevue/toast";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ConfirmDialog from "primevue/confirmdialog";
import { useAuthStore } from "./hooks/useAuthStore";
import { useDeviceLocation } from "./hooks/useDeviceLocation";
import { useInvitationStore } from "./hooks/useInvitationStore";
import { useWebSocket } from "./hooks/useWebsocketConnection";
import { usePushNotifications } from "./hooks/usePushNotifications";
import useTutorial from "./hooks/useTutorial";
import tutorialSteps from "./tutorial.config";

import LiveTimeFromNow from "./components/LiveTimeFromNow.vue";
useWebSocket();
const { isAuthenticated, isAuthenticating } = useAuthStore();
const { startLocationTracking, stopLocationTracking } = useDeviceLocation();
const { startInviteLoop, stopInviteLoop } = useInvitationStore();

watch(
  isAuthenticated,
  () => {
    if (isAuthenticated.value) {
      startLocationTracking();
      startInviteLoop();
      if (
        Capacitor.getPlatform() === "ios" ||
        Capacitor.getPlatform() === "android"
      ) {
        usePushNotifications();
      }
    } else {
      stopLocationTracking();
      stopInviteLoop();
    }
  },
  { immediate: true }
);
function updateHeight() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

// Update the height on page load and on window resize
window.addEventListener("resize", updateHeight);
window.addEventListener("load", updateHeight);

const router = useRouter();

const tutorial = useTutorial();
const step = ref(tutorial.step);
// const { step } = toRefs(tutorial);
const steps = tutorialSteps(router);
watch(
  tutorial,
  () => {
    step.value = tutorial.step;
  },
  { deep: true }
);
tutorial.init({ steps });
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100);
}
.content {
  position: relative;
  display: flex;
  flex-grow: 1;
}
</style>
