<template>
  <div :class="['files-grid', `layout-${files?.length}`]">
    <div
      v-for="(file, index) in visibleFiles"
      :key="file.id"
      :class="['file-item', getClassForIndex(index, visibleFiles.length)]"
      @click="(e) => openModal(index, e)"
    >
      <div v-if="isUploading(file)" class="preview-media">
        <p>{{ getProgress(file) <= 0 ? "Processing..." : "Uploading..." }}</p>
        <progress
          :value="getProgress(file)"
          max="100"
          class="block mb-2 w-full"
        ></progress>
        <Button @click="cancelUpload(noteId, file.id)" outlined>Cancel</Button>
      </div>
      <template v-else>
        <lazy-image
          v-if="isImage(file.filePath)"
          :signedUrl="file.signedUrl"
          class="preview-media"
          crossOrigin="anonymous"
        />

        <div class="preview-media" v-else-if="isAudio(file.filePath)">
          <audio
            :src="file.signedUrl"
            controls
            class="preview-media"
            crossOrigin="anonymous"
          ></audio>
        </div>

        <div class="preview-media" v-else-if="isVideo(file.filePath)">
          <Button text icon="pi pi-ellipsis-v" class="open" />
          <video
            :src="file.signedUrl"
            controls
            preload="metadata"
            :poster="file.previewSignedUrl"
            @click="(e) => e.preventDefault()"
            crossOrigin="anonymous"
          ></video>
        </div>

        <PdfViewer
          v-else-if="isPDF(file.previewKey || file.filePath)"
          :signedUrl="file.previewSignedUrl || file.signedUrl"
        />

        <div v-else class="file-name">{{ file.name }}</div>

        <div
          v-if="remainingCount > 0 && index === visibleFiles?.length - 1"
          class="file-item more-files"
        >
          +{{ remainingCount }} more
        </div>
      </template>
    </div>

    <Dialog
      v-model:visible="isModalOpen"
      :fullScreen="true"
      :closable="false"
      @hide="closeModal"
      class="media-dialog"
    >
      <div class="flex gap-1 mt-2 mx-1">
        <Button text icon="pi pi-times" @click="closeModal" />
        <div class="flex-1" />
        <!-- <Button icon="pi pi-download" @click="downloadCurrentFile" />
        <Button
          icon="pi pi-pencil"
          v-if="isImage(currentFile.filePath) || isVideo(currentFile.filePath)"
          @click="openPhotoEditor"
        />
        <Button
          icon="pi pi-trash"
          severity="danger"
          @click="deleteFileFn(currentFile)"
        /> -->
        <Button
          icon="pi pi-ellipsis-h"
          text
          severity="secondary"
          @click="menu.toggle($event)"
        />
        <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
      </div>
      <!-- <div class="fake" /> -->
      <div
        class="modal-content"
        :key="currentFile.name"
        @touchstart="handleTouchStart"
        @touchend="handleTouchEnd"
        @touchmove="handleTouchMove"
      >
        <button
          @click="previousFile"
          class="nav-button prev-button"
          v-if="files?.length > 1"
        >
          ‹
        </button>
        <div ref="zoomContainer" class="zoom-container">
          <div v-if="isUploading(currentFile)" class="modal-media">
            <p>Uploading {{ currentFile.name }}...</p>
            <progress
              :value="getProgress(currentFile)"
              max="100"
              class="block mb-2 w-full"
            ></progress>
            <Button @click="cancelUpload(noteId, currentFile.id)" outlined
              >Cancel</Button
            >
          </div>

          <!-- Image Modal -->
          <lazy-image
            v-if="isImage(currentFile.filePath)"
            :signedUrl="currentFile.signedUrl"
            class="modal-media"
            ref="modalImage"
            crossOrigin="anonymous"
          />

          <!-- Audio Modal -->
          <audio
            v-else-if="isAudio(currentFile.filePath)"
            :src="currentFile.signedUrl"
            controls
            class="modal-media"
            crossOrigin="anonymous"
          ></audio>

          <!-- Video Modal -->
          <video
            v-else-if="isVideo(currentFile.filePath)"
            :src="currentFile.signedUrl"
            controls
            preload="auto"
            muted
            autoplay
            playsinline
            class="modal-media"
            crossOrigin="anonymous"
          ></video>

          <!-- PDF Modal -->
          <PdfViewer
            v-else-if="isPDF(currentFile.previewKey || currentFile.filePath)"
            :signedUrl="currentFile.previewSignedUrl || currentFile.signedUrl"
          />
        </div>
        <button
          @click="nextFile"
          class="nav-button next-button"
          v-if="files?.length > 1"
        >
          ›
        </button>
      </div>
      <Dialog
        v-model:visible="isPhotoEditorOpen"
        :fullScreen="true"
        :closable="false"
        @hide="isPhotoEditorOpen = false"
        class="media-dialog"
      >
        <PhotoEditorDialog
          v-if="isPhotoEditorOpen"
          :file="{ ...currentFile, ...editFile }"
          @close="isPhotoEditorOpen = false"
          :onSave="handleSaveEdits"
        />
      </Dialog>
    </Dialog>
    <Drawer
      v-model:visible="showDetails"
      position="right"
      header="File details"
      class="file-details-panel"
    >
      <div class="note-details-content">
        <div class="note-details-row">
          <strong>Owner:</strong>
          <span> <UserElm :id="currentFile?.userId" /></span>
        </div>
        <div class="note-details-row">
          <strong>Date Created:</strong>
          <span>{{ formatDate(currentFile?.createdAt) }}</span>
        </div>
        <div class="note-details-row">
          <strong>Size:</strong>
          <span>{{
            currentFile?.totalBytes
              ? formatBytes(currentFile?.totalBytes)
              : "Unavailable"
          }}</span>
        </div>
        <div class="note-details-row">
          <strong>Auto-Detected:</strong>
          <span>{{
            currentFile?.keywords > " " ? currentFile?.keywords : "Unavailable"
          }}</span>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  toRefs,
  onMounted,
  onBeforeUnmount,
} from "vue";
import Dialog from "primevue/dialog";
import Drawer from "primevue/drawer";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { useConfirm } from "primevue/useconfirm";
import PdfViewer from "./PdfViewer.vue"; // Import the PDF Viewer component
import PhotoEditorDialog from "./PhotoEditorDialog.vue"; // Import your photo editor component
import LazyImage from "./LazyImage";
import UserElm from "../UserElm";
import { useUploadStore } from "../../hooks/useUploadStore"; // Function to save the edited image
import { useSwipeHandler } from "../../hooks/useSwipe";
import { isImage, isAudio, isVideo, isPDF } from "../../utils/helpers";
import { useFileStore } from "../../hooks/useFileStore";
import {
  // safeBackRoute,
  // isMobile,
  formatBytes,
  dateToShortUrlFormat,
} from "../../utils/helpers";

const { deleteNoteFile, getFileEditUrl, createNoteFile } = useFileStore();
const confirm = useConfirm();
const {
  uploadFile,
  uploadProgress,
  uploadStatus,
  cancelUpload,
  getUploadingFilesByNoteId,
} = useUploadStore();
const { handleTouchStart, handleTouchEnd, handleTouchMove } = useSwipeHandler({
  direction: "down,left,right",
  onSwipeLeft: () => {
    nextFile();
  },
  onSwipeRight: () => {
    previousFile();
  },
  onSwipeDown: () => {
    isModalOpen.value = false;
  },
  feedbackElement: ".modal-content", //".fake",
  scrollContainerSelector: ".modal-content",
});

const props = defineProps({
  files: Array,
  noteId: {
    type: String,
    required: true,
  },
  isOwner: Boolean,
  limit: {
    type: Number,
    default: 5, // Number of files to show in the grid
  },
});

const { files, noteId } = toRefs(props);

const formatDate = (d) => dateToShortUrlFormat(new Date(d));

const menu = ref();
const showDetails = ref(false);
const items = computed(() => {
  const baseItems = [
    {
      label: "Details",
      icon: "pi pi-info-circle",
      command: () => {
        showDetails.value = true;
      },
    },
    {
      label: "Download",
      icon: "pi pi-download",
      command: () => {
        downloadCurrentFile();
      },
    },
    props.isOwner ? { separator: true } : null,
    props.isOwner
      ? {
          label: "Edit",
          icon: "pi pi-pencil",
          command: () => {
            openPhotoEditor();
          },
        }
      : null,

    props.isOwner
      ? {
          label: "Delete",
          icon: "pi pi-trash",
          class: "p-menu-item-danger",
          command: () => {
            deleteFileFn(currentFile.value);
          },
        }
      : null,
  ];

  // Filter out null or undefined items
  return baseItems.filter((item) => item !== null);
});
const isModalOpen = ref(false);
const isPhotoEditorOpen = ref(false);
const modalFileIndex = ref(null);
const pendingFiles = getUploadingFilesByNoteId(props.noteId);

// Fetch files being uploaded for the noteId
const visibleFiles = computed(() => {
  return [...(files.value ?? []), ...(pendingFiles.value ?? [])].slice(
    0,
    props.limit
  );
});

const remainingCount = computed(() => {
  return (
    [...(files.value ?? []), ...(pendingFiles.value ?? [])].length - props.limit
  );
});

const isUploading = (file) => {
  return uploadStatus.value[`${noteId.value ?? "new"}-${file.id}`];
};

const getProgress = (file) => {
  return uploadProgress.value[noteId.value ?? "new"]?.[file.id] || 0;
};

const openModal = (index, e) => {
  e.stopPropagation();
  modalFileIndex.value = index;
  isModalOpen.value = true;
  document.body.style.overflow = "hidden";
};

const closeModal = () => {
  isModalOpen.value = false;
  modalFileIndex.value = null;
  document.body.style.overflow = "";
};

const previousFile = () => {
  modalFileIndex.value =
    modalFileIndex.value > 0
      ? modalFileIndex.value - 1
      : files.value.length - 1;
};

const nextFile = () => {
  modalFileIndex.value =
    modalFileIndex.value < files.value.length - 1
      ? modalFileIndex.value + 1
      : 0;
};

const currentFile = computed(() => files.value[modalFileIndex.value] || {});
const editFile = ref();

const downloadCurrentFile = async () => {
  // TODO: get download url
  const link = document.createElement("a");
  link.target = "_blank";
  link.href = currentFile.value.signedUrl;
  link.download = currentFile.value.name;
  link.click();
};

const openPhotoEditor = async () => {
  editFile.value = await getFileEditUrl(currentFile.value.id);
  console.log("editFile", editFile.value);
  isPhotoEditorOpen.value = true;
};

const handleSaveEdits = async (editedFile, opts = {}) => {
  // Upload the edited image
  const savedFile = await uploadFile(editedFile, props.noteId);
  if (!opts?.isReplace) {
    const newNoteFile = await createNoteFile([savedFile.id], props.noteId);
    console.log("TODO: save to note", newNoteFile);
    if (newNoteFile[0]) {
      modalFileIndex.value = files.value.findIndex(
        (f) => f.id === newNoteFile[0].id
      );
      isPhotoEditorOpen.value = false;
      editFile.value = null;
    }
  }

  //
};

// Handle keyboard navigation
const handleKeydown = (event) => {
  if (event.key === "ArrowLeft") {
    previousFile();
  } else if (event.key === "ArrowRight") {
    nextFile();
  } else if (event.key === "Escape") {
    closeModal();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener("keydown", handleKeydown);
});

// Method to get the appropriate class based on the index and total length
const getClassForIndex = (index, length) => {
  const isImg = isImage(files.value[index]?.filePath);
  const isAdo = isAudio(files.value[index]?.filePath);
  const is_pdf = isPDF(
    files.value[index]?.previewKey || files.value[index]?.filePath
  );
  const elm = isImg ? "img" : isAdo ? "audio" : is_pdf ? "pdf" : "video";
  if (length === 1) return `one ${elm}`;
  if (length === 2) return `two ${elm}`;
  if (length === 3) return index === 0 ? `one ${elm}` : `two ${elm}`;
  if (length === 4) return `two ${elm}`;
  if (length >= 5)
    return index === 0 || index === 1 ? `two ${elm}` : `three ${elm}`;
  return `three ${elm}`; // Fallback for more than 5 files
};

const deleteFileFn = (file) => {
  console.log("delete file", file);
  confirmDelete(
    () => {
      console.log("TODO delete file", file);
      // make sure this is one to call the api with
      closeModal();
      deleteNoteFile(file.id, noteId.value);
    },
    () => {}
  );
};

const confirmDelete = (accept, reject) => {
  confirm.require({
    message: `<p>Are you sure you want to remove this file?</p>
    <p class="font-bold">Note: This action is permanent and cannot be undone.</p>
    <p>Would you like to continue?</p>`,
    header: "Delete File",
    // icon: "pi pi-user",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Yes, Continue",
      severity: "danger",
    },
    accept,
    reject,
  });
};
</script>

<style>
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.prev-button {
  left: 10px;
}

.next-button {
  right: 10px;
}

.zoom-container {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoom-container video {
  width: 70%;
}
.media-dialog .p-dialog-content {
  height: 100%;
  flex: 1;
  padding-top: constant(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  /* display: flex;
  align-items: center; */
}
audio {
  display: block !important;
  width: 80%;
}

video {
  max-width: 80vw;
}
</style>

<style>
.files-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  position: relative;
  width: 100%;
  max-width: 500px;
  /* max-width: 400px; */
  /* max-width: 320px; */
  margin: 0 auto;
}

.file-item {
  position: relative;
  cursor: pointer;
  flex-grow: 1;
}
.file-item img {
  top: 0;
  left: 0;
  width: 100%;
}
.file-preview {
  width: 100%;
  max-height: 100%;
  /* height: 100%; */
  object-fit: cover;
}
.p-button.open {
  position: absolute;
  right: 10px;
}
.one {
  grid-column: span 2;
  grid-row: span 2;

  width: 100%;
}
.one {
  padding-top: 100%;
}
.one.video {
  padding-top: 70%;
}
.one.img {
  padding-top: 100%;
}
.one img {
  position: absolute;
}

.pdf .pdf-container {
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
}
.pdf .pdf-navigation {
  display: none;
}
.two {
  grid-column: span 1;
  grid-row: span 1;
  width: 48%;
}
.two {
  padding-top: 50%;
}

.three {
  grid-column: span 1;
  grid-row: span 1;
  /* aspect-ratio: 1 / 1; */
  width: 31.5%;
  padding-top: 33.3333%;
}
.three.img {
  /* padding-top: 33.3333%; */
}

.three img {
  position: absolute;
}
.more-files {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 18px;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* max-width: 90%; */
  max-height: 90%;

  height: 100%;
  flex: 1;
  overflow-y: auto;
  justify-content: center;
}
.pinch-zoom-container {
  width: 100%;
}
.modal-media {
  max-width: 100%;
  max-height: 80vh;
  cursor: pointer;
}
.preview-media {
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
  border: 1px solid var(--corp-primary-color);
  border-radius: 10px;
}
.preview-media video {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.preview-media audio {
  width: 100%;
  margin: 0 auto;
}
.nav-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  padding: 10px 6px;
  z-index: 1000;
}

.prev-button {
  left: 0px;
}

.next-button {
  right: 0px;
}

.zoom-container {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.media-dialog .p-dialog-content {
  height: 100%;
  flex: 1;
  /* display: flex;
  align-items: center; */
  padding: 0;
}
</style>

<style>
.files-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  position: relative;
}

.file-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.file-item img {
  top: 0;
  left: 0;
  width: 100%;
}
.file-preview {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.modal-content {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* max-width: 90%; */
  max-height: 90%;

  height: 100%;
  flex: 1;
  overflow-y: auto;
  justify-content: center;
}
.pinch-zoom-container {
  width: 100%;
}
.modal-image {
  max-width: 100%;
  max-height: 80vh;
  cursor: pointer;
}
</style>
