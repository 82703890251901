import { watch, onMounted, onUnmounted } from "vue";
import { useWebSocket } from "./useWebsocketConnection";

const callbacks = new Set(); // Centralized callbacks storage

// Shared event handlers
const handleVisibilityChange = () => {
  if (document.visibilityState === "visible") {
    callbacks.forEach((cb) => cb());
  }
};

export function useReload(callback) {
  const { isConnected } = useWebSocket();

  // Add the callback to the set
  callbacks.add(callback);

  // Watch for WebSocket connection changes
  watch(isConnected, () => {
    if (isConnected.value) {
      callbacks.forEach((cb) => cb());
    }
  });

  onMounted(() => {
    if (callbacks.size === 1) {
      // Add the event listener only once when the first hook instance is mounted
      document.addEventListener("visibilitychange", handleVisibilityChange);
    }
  });

  onUnmounted(() => {
    // Remove the callback from the set
    callbacks.delete(callback);

    if (callbacks.size === 0) {
      // Remove the event listener when no more hooks are active
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    }
  });
}
