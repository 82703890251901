<template>
  <div class="media-editor">
    <MediaEditor
      :url="url"
      :isVideo="isVideo(file?.filePath)"
      :onSave="saveFile"
    >
      <template v-slot:close>
        <Button @click="emit('close')" icon="pi pi-times"></Button>
      </template>
    </MediaEditor>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import Button from "primevue/button";
import MediaEditor from "media-editor";
import "media-editor/dist/style.css";
import { isVideo } from "../../utils/helpers";

const props = defineProps({ file: Object, onSave: Function });
const emit = defineEmits(["close"]);

const saveFile = async (file) => {
  if (props.onSave) {
    const result = await props.onSave(file);
    console.log("upload result", result);
  }
};

const url = props.file?.previewSignedUrl || props.file?.signedUrl;
</script>

<style scoped>
.media-editor {
  position: absolute;
  top: 0;
  bottom: 0;
  background: #fff;
  width: 100%;
  z-index: 1001;
}
</style>
