<template>
  <Dialog
    v-model:visible="visible"
    @hide="close"
    :position="'bottom'"
    class="note-details"
    :draggable="false"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
    header="Details"
  >
    <div class="note-details-content">
      <div class="note-details-row">
        <strong>Owner:</strong> <span><UserElm :id="note?.userId" /></span>
      </div>

      <div class="note-details-row">
        <strong>Date Created:</strong>
        <span>{{ formatDate(note?.createdAt) }}</span>
      </div>
      <div class="note-details-row">
        <strong>Last Updated:</strong>
        <span>{{ formatDate(note?.updatedAt) }}</span>
      </div>
      <div class="note-details-row">
        <strong>Pinned:</strong>
        <span>{{ note?.isPinned ? "Pinned" : "Unpinned" }}</span>
      </div>
      <div class="note-details-row">
        <strong>Color:</strong>
        <span class="capitalize">{{ note?.color }}</span>
      </div>
      <div class="note-details-row">
        <strong>Files:</strong>
        <span>
          <template v-if="note?.files?.length">
            {{ note?.files?.length }} files
          </template>
          <template v-else> No files </template>
        </span>
      </div>
      <div class="note-details-row">
        <strong>Tasks:</strong>
        <span>
          <template v-if="note?.tasks?.length">
            {{ note?.tasks?.length }} tasks
          </template>
          <template v-else> No tasks </template>
        </span>
      </div>
      <div class="note-details-row">
        <strong>Labels:</strong>
        <span>
          <template v-if="note?.labels?.length">
            {{ note?.labels?.map((l) => l.name).join(", ") }}
          </template>
          <template v-else> No tags </template>
        </span>
      </div>
    </div>
  </Dialog>
</template>

<script setup>
import { ref, defineProps, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Dialog from "primevue/dialog";
import UserElm from "../UserElm.vue";
import { useSwipeHandler } from "../../hooks/useSwipe";
import {
  safeBackRoute,
  // isMobile,
  dateToShortUrlFormat,
} from "../../utils/helpers";
import { useNoteStore } from "../../hooks/useNoteStore";

const { fetchNoteById } = useNoteStore();
const props = defineProps({ noteId: String });
const visible = ref(false);
const router = useRouter();
const route = useRoute();

const formatDate = (d) => dateToShortUrlFormat(new Date(d));

const { handleTouchStart, handleTouchEnd, handleTouchMove } = useSwipeHandler({
  direction: "down",
  onSwipeDown: () => {
    close();
  },
  feedbackElement: `.note-details`,
  scrollContainerSelector: `.note-details`,
});

const close = () => {
  const currentQuery = route.query;
  const path = safeBackRoute();
  router.push({ path, query: currentQuery });
};

const note = ref();

watch(
  props.noteId,
  () => {
    note.value = null;
    const fn = async () => {
      const res = await fetchNoteById(props.noteId, {
        lazy: true,
      });
      note.value = res;
      visible.value = true;
    };
    fn();
  },
  { immediate: true }
);
</script>

<style>
.note-details {
  width: 500px;
  max-width: 100%;
}
.note-details-content {
  padding: 16px;
  font-size: 14px;
  line-height: 1.5;
}
.note-details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.note-details-row strong {
  margin-right: 8px;
}
</style>
