import { computed, ref } from "vue";
import axios from "axios";
import store from "../store";
import api from "../utils/api";

let lastQuery = null;
let cancelTokenSource;
export function useNoteStore() {
  const isLoading = ref(false);
  const notes = computed(() => store.state.notes);

  async function fetchNotes(query) {
    try {
      lastQuery = query;
      isLoading.value = true;
      // Cancel the previous request if it's still pending
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Cancelled duplicate request");
      }

      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();
      //   store.mutations.setState("notes", []);
      const response = await api.get("/api/notes", {
        params: query,
        cancelToken: cancelTokenSource.token,
      });
      store.mutations.setState("notes", response.data);
      isLoading.value = false;
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        store.mutations.setState("notes", []);
        console.error("Error fetching notes:", error);
        isLoading.value = false;
      }
    }
  }

  async function reFetchNotes() {
    try {
      // Cancel the previous request if it's still pending
      if (cancelTokenSource) {
        cancelTokenSource.cancel("Cancelled duplicate request");
      }

      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();
      //   store.mutations.setState("notes", []);
      const response = await api.get("/api/notes", {
        params: lastQuery,
        cancelToken: cancelTokenSource.token,
      });
      store.mutations.setState("notes", response.data);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request canceled:", error.message);
      } else {
        store.mutations.setState("notes", []);
        console.error("Error fetching notes:", error);
      }
    }
  }

  async function createNote(noteData) {
    try {
      const response = await api.post("/api/notes", noteData);
      //   store.mutations.addModel("notes", response.data);
      await fetchNotes(lastQuery);
      return response.data;
    } catch (error) {
      console.error("Error creating note:", error);
      throw error;
    }
  }

  async function updateNote(id, noteData) {
    try {
      const response = await api.put(`/api/notes/${id}`, noteData);
      store.mutations.updateModel("notes", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating note:", error);
      throw error;
    }
  }

  async function deleteNote(id) {
    try {
      await api.delete(`/api/notes/${id}`);
      store.mutations.removeModel("notes", id);
    } catch (error) {
      console.error("Error deleting note:", error);
      throw error;
    }
  }

  async function fetchNoteById(noteId, { lazy, skipMutations }) {
    try {
      let note = store.state.notes?.find((note) => note.id === noteId);
      if (!lazy || !note) {
        const response = await api.get(`/api/notes/${noteId}`);

        if (!note) {
          note = response.data;
          if (!skipMutations) store.mutations.addModel("notes", response.data);
        } else {
          if (!skipMutations)
            store.mutations.updateModel("notes", noteId, response.data);
        }
      }
      return note;
    } catch (error) {
      console.error("Error fetching note by id:", error);
      throw error;
    }
  }

  async function fetchNoteByIdAndToken(noteId, token) {
    try {
      const response = await api.get(`/api/notes/${noteId}/${token}`);

      return response.data;
    } catch (error) {
      console.error("Error fetching note by id:", error);
      throw error;
    }
  }
  async function searchNotes(query) {
    try {
      return await api.get("/api/notes", { params: query })?.data;
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  }

  return {
    notes,
    lastQuery,
    isLoading,
    fetchNotes,
    reFetchNotes,
    createNote,
    updateNote,
    deleteNote,
    fetchNoteById,
    fetchNoteByIdAndToken,
    searchNotes,
  };
}
