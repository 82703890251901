<template>
  <div class="tasks-container">
    <div v-for="(task, index) in tasks" :key="task.id" class="task-item">
      <template v-if="!preview">
        <!-- <div class="sort-handle" v-if="!preview && index !== tasks.length - 1">
          ⋮⋮
        </div> -->

        <input
          :disabled="readOnly"
          type="checkbox"
          :checked="task.completed"
          @change="
            updateTask(index, { ...task, completed: !task.completed }, true)
          "
        />
        <div class="task-input-wrap">
          <div
            :class="{ 'task-input': true, completed: task.completed }"
            :contenteditable="!readOnly"
            @input="
              (e) => {
                //addTask(index);
                //updateTask(index, {
                //  description: e.target.innerText,
                //});
              }
            "
            @focus="() => (lastVal = task.description)"
            @blur="
              (e) => {
                console.log('blur');
                if (task.description !== e.target.innerText) {
                  updateTask(
                    index,
                    {
                      ...task,
                      description: e.target.innerText,
                    },
                    true
                  );
                }
                lastVal = null;
              }
            "
            @keydown.enter="
              (e) => {
                e.preventDefault();
                handleTaskEnter(index);
              }
            "
            @keydown.delete="handleTaskDelete(index, $event)"
            :placeholder="!task.description ? 'List item' : ''"
            v-html="task.description"
          />
          <div class="text-sm flex gap-1">
            <Chip
              v-if="
                task.responsibleUser && task.responsibleUser?.id !== user?.id
              "
              :label="task.responsibleUser.email"
              class="task-tag"
            />
            <Chip
              v-if="
                task.responsibleTemporaryCollaborator &&
                task.responsibleTemporaryCollaborator?.id
              "
              :label="`${getTemporaryCollaboratorDisplay(
                task.responsibleTemporaryCollaborator
              )} - (guest)`"
              class="task-tag"
              v-tooltip="
                `This user is not registered and will not receive updates or notifications, but the shared link allows them to see the latest changes.`
              "
            />
            <Chip
              v-if="task.dueDate"
              :label="`${!task.completed ? formatDate(task.dueDate) : ''}`"
              v-tooltip.top="format(task.dueDate, 'MMM dd,yyy hh:mm a')"
              class="task-tag"
            />
          </div>
        </div>

        <div class="task-more-menu" v-if="expanded && !readOnly && !preview">
          <Button
            icon="pi pi-info-circle"
            class="p-button-text"
            @click="toggleMenu(index)"
            size="small"
          />

          <div
            v-if="expandedMenuIdx[index]"
            :class="[
              'task-options-menu',
              `task-options-menu-${index}`,
              { above: isAbove[index], below: !isAbove[index] },
            ]"
          >
            <div class="flex">
              <div class="flex-1">Manage Task</div>
              <Button
                icon="pi pi-times"
                class="p-button-text"
                @click="toggleMenu(index)"
              />
            </div>
            <ResponsiblePersonPicker
              :responsibleUserEmail="
                task?.responsibleUser?.email ||
                getTemporaryCollaboratorDisplay(
                  task.responsibleTemporaryCollaborator
                )
              "
              :noteId="noteId"
              @onUserSelect="
                (user) =>
                  saveTask({
                    ...task,
                    responsibleUser: user,
                    responsibleUserId: null,
                    responsibleTemporaryCollaboratorId: null,
                  })
              "
            />
            <DueDatePicker
              :dueDate="task.dueDate"
              @onDueDateChange="(date) => saveTask({ ...task, dueDate: date })"
            />
            <Button
              v-if="!readOnly && !preview"
              icon="pi pi-trash"
              label="Delete"
              class="w-full mt-4"
              severity="danger"
              @click="
                () => {
                  delete expandedMenuIdx[index];
                  removeTask(index);
                }
              "
            />
          </div>
        </div>
      </template>
    </div>
    <Button
      v-if="expanded"
      class="ml-1 mb-2 add"
      text
      icon="pi pi-plus"
      label="Add Task Item"
      @click="handleTaskEnter(tasks.length - 1, true)"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted, ref, nextTick } from "vue";
import {
  format,
  differenceInSeconds,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import Button from "primevue/button";
import Chip from "primevue/chip";

import ResponsiblePersonPicker from "./ResponsiblePersonPicker.vue";
import DueDatePicker from "./DueDatePicker.vue";
import { useTasks } from "./hooks/useTasks";
import { useAuthStore } from "../../hooks/useAuthStore";
import { getTemporaryCollaboratorDisplay } from "../../utils/helpers";

const emit = defineEmits(["update"]);

const { user } = useAuthStore();

const props = defineProps({
  tasks: Array,
  preview: Boolean,
  expanded: Boolean,
  noteId: String,
  readOnly: Boolean,
});

const lastVal = ref("");
const expandedMenuIdx = ref({});
const isAbove = ref({}); // Track position for each task menu

const {
  tasks,
  // addTask,
  saveTask,
  removeTask,
  handleTaskEnter,
  handleTaskDelete,
  updateTask,
  focusLastTask,
} = useTasks(props, (val) => {
  emit("update", val);
});

const formatDate = (time) => {
  const now = new Date();
  const targetTime = new Date(time);

  const seconds = differenceInSeconds(targetTime, now);
  const minutes = differenceInMinutes(targetTime, now);
  const hours = differenceInHours(targetTime, now);
  const days = differenceInDays(targetTime, now);

  if (seconds < 60 && seconds > -1) {
    return "now";
  } else if (minutes < 60 && minutes > -60) {
    return `${minutes <= -1 ? "Overdue " : "Due "} ${Math.abs(minutes)}m`; // X minutes
  } else if (hours < 24 && hours > -24) {
    console.log("hours", hours);
    return `${hours <= -1 ? "Overdue " : "Due "} ${Math.abs(hours)}h`; // X hours
  } else {
    return `${days <= -1 ? "Overdue " : "Due "}${Math.abs(days)}d`; // X days
  }
};

// Toggle the menu and dynamically set its position
const toggleMenu = (index) => {
  // Close any open menus first
  Object.keys(expandedMenuIdx.value).forEach((key) => {
    if (key != index) {
      expandedMenuIdx.value[key] = false; // Close all other menus
      isAbove.value[key] = false;
    }
  });
  expandedMenuIdx.value[index] = !expandedMenuIdx.value[index];

  nextTick(() => {
    if (expandedMenuIdx.value[index]) {
      positionMenu(index);
    } else {
      isAbove.value[index] = false;
    }
  });
};

// Position menu based on available space
const positionMenu = (index) => {
  const menu = document.querySelector(`.task-options-menu-${index}`);

  if (menu) {
    const { top, height } = menu.getBoundingClientRect();
    console.log("menu", top, height);
    const windowHeight = window.innerHeight;
    // Check if there's more space above or below the menu button
    isAbove.value[index] = top + height > windowHeight - 100;
  }
};

onMounted(() => {
  if (props.tasks[0]?.id?.indexOf("NEW") > -1) {
    nextTick(() => {
      focusLastTask(1);
    });
  }
});
</script>

<style scoped>
.tasks-container {
  margin-top: 10px;
  text-align: left;
  /* overflow: hidden; */
  font-size: 1rem;
}
.tasks-container .add {
  color: #999;
  font-size: 0.8rem;
  padding-left: 10px;
}
.task-item {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  color: inherit;
  /* overflow: hidden; */
}

.task-item input::placeholder {
  color: inherit;
}

.sort-handle {
  cursor: grab;
}

.task-input-wrap {
  width: 100%;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 0.5rem;
}
.task-input {
  border: none;
  padding-left: 0.5rem;
  outline: none;
  line-height: 1.5rem;
  overflow: hidden;
}

.task-input.completed {
  text-decoration: line-through;
}
.task-more-menu {
  position: relative;
}

.task-more-menu .p-button-icon-only {
  color: #ccc;
}
.task-options-menu {
  width: 300px;
  position: absolute;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 8px;
  z-index: 10;
  transform-origin: top;
}

.task-options-menu.above {
  top: auto;
  bottom: 100%;
  margin-bottom: 5px;
}

.task-options-menu.below {
  top: 100%;
  margin-top: 5px;
}

.pi-plus {
  padding-left: 1.25rem;
}

.task-options-menu p {
  margin: 0;
  padding: 4px 8px;
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
}

.task-options-menu p:hover {
  background-color: #f0f0f0;
}
.task-tag {
  font-size: 0.7rem;
  padding: 0.25rem 0.5rem;
  background-color: var(--task-tag-back);
  color: #1b1b1b;
}
</style>
