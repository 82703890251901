export default class WorkerPool {
  constructor(workerUrl, poolSize = 3) {
    this.workerUrl = workerUrl;
    this.poolSize = poolSize;
    this.workers = [];
    this.taskQueue = [];
    this.idleWorkers = [];

    for (let i = 0; i < poolSize; i++) {
      this.addWorker();
    }
  }

  addWorker() {
    const worker = new Worker(this.workerUrl);
    worker.onmessage = (event) => this.handleWorkerMessage(worker, event);
    worker.onerror = (error) => {
      console.error("Worker error:", error);
      this.replaceWorker(worker);
    };
    this.workers.push(worker);
    this.idleWorkers.push(worker);
    this.processNextTask();
  }

  handleWorkerMessage(worker, event) {
    const { resolve, reject } = worker.currentTask;
    delete worker.currentTask;

    // Resolve or reject the task promise based on the worker result
    if (event.data.success) {
      resolve(event.data.file);
    } else {
      reject(new Error(event.data.error));
    }

    // Terminate the worker after the task is completed
    this.replaceWorker(worker);
  }

  processNextTask() {
    if (this.taskQueue.length && this.idleWorkers.length) {
      const worker = this.idleWorkers.pop();
      const { task, resolve, reject } = this.taskQueue.shift();
      worker.currentTask = { resolve, reject };
      worker.postMessage(task);
    }
  }

  runTask(task) {
    return new Promise((resolve, reject) => {
      this.taskQueue.push({ task, resolve, reject });
      this.processNextTask();
    });
  }

  replaceWorker(worker) {
    this.workers = this.workers.filter((w) => w !== worker);
    this.addWorker();
  }
}
