<template>
  <aside :class="`sidebar ${isSidebarOpen ? 'opened' : 'closed'}`">
    <ul>
      <li v-for="label in topNav" :key="label.name" @click="clickClose">
        <component
          :is="label.route ? RouterLink : 'span'"
          :to="label.route"
          :class="{ activeLink: isActive(label.route || {}) }"
        >
          <i :class="label.icon"></i>
          {{ label.name }}
        </component>
      </li>
    </ul>

    <ul>
      <li v-for="label in sidebarLabels" :key="label.name" @click="clickClose">
        <component
          :is="label.route ? RouterLink : 'span'"
          :to="label.route"
          :class="{ activeLink: isActive(label.route || {}) }"
        >
          <i :class="label.icon"></i>
          {{ label.name }}
        </component>
      </li>
    </ul>
    <div class="flex-1" />
    <footer>
      <ul>
        <li v-for="label in bottomlabels" :key="label.name" @click="clickClose">
          <component
            :is="label.route ? RouterLink : 'span'"
            :to="label.route"
            :class="{ activeLink: isActive(label.route || {}) }"
          >
            <i :class="label.icon"></i>
            {{ label.name }}
          </component>
        </li>
      </ul>
    </footer>

    <!-- Edit Labels Dialog -->
    <Dialog
      v-if="isEditDialogVisible"
      header="Edit labels"
      :visible="isEditDialogVisible"
      @update:visible="isEditDialogVisible = !isEditDialogVisible"
      modal
    >
      <!-- Dialog content for editing labels -->
      <div class="edit-labels">
        <div v-for="label in editableLabels" :key="label" class="label-item">
          <i class="pi pi-tag"></i>
          {{ label }}
          <i class="pi pi-pencil" style="margin-left: auto"></i>
        </div>
        <Button label="Done" @click="isEditDialogVisible = false" />
      </div>
    </Dialog>
  </aside>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useRoute, RouterLink } from "vue-router";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import { useSidebarState } from "../hooks/useSidebarState";
import { useLabelStore } from "../hooks/useLabelStore";
import { useReload } from "../hooks/useReload";
import { isMobile } from "../utils/helpers";

const route = useRoute();

const isActive = ({ path, query }) => {
  const currentPath = route.path;
  const currentQuery = route.query;
  return (
    currentPath === path &&
    JSON.stringify(currentQuery) === JSON.stringify(query)
  );
};

const { isSidebarOpen, toggleSidebar } = useSidebarState();
const { labels, fetchLabels } = useLabelStore();

useReload(fetchLabels);
const topNav = [
  { name: "Home", icon: "pi pi-file", route: { path: "/", query: {} } },
  // {
  //   name: "Calendar",
  //   icon: "pi pi-calendar",
  //   route: { path: "/calendar", query: {} },
  // },
  {
    name: "Pinned",
    icon: "pi pi-thumbtack",
    route: { path: "/", query: { pinned: "true" } },
  },
  {
    name: "Shared with me",
    icon: "pi pi-share-alt",
    route: { path: "/", query: { owner: "sharedWithMe" } },
  },
  {
    name: "Tasks",
    icon: "pi pi-check-circle",
    route: { path: "/", query: { hasTask: "true" } },
  },
  {
    name: "Files",
    icon: "pi pi-image",
    route: { path: "/", query: { hasFile: "true" } },
  },
  {
    name: "Reminders",
    icon: "pi pi-bell",
    route: { path: "/", query: { hasReminder: "true" } },
  },
];

const sidebarLabels = computed(() =>
  labels.value?.map((label) => ({
    name: label.name,
    icon: "pi pi-tag",
    route: { path: "/", query: { labels: label.name } },
  }))
);
const bottomlabels = [
  // {
  //   name: "Archived",
  //   icon: "pi pi-trash",
  //   route: { path: "/archived", query: {} },
  // },
  // {
  //   name: "Trash",
  //   icon: "pi pi-trash",
  //   route: { path: "/deleted", query: {} },
  // },
];
const isEditDialogVisible = ref(false);

const editableLabels = labels.value
  ?.filter((label) => !["Archive", "Trash", "Edit labels"].includes(label.name))
  .map((label) => label.name);

// const handleClick = (label) => {
//   if (label.route) {
//     router.push(label.route);
//   } else if (label.name === "Edit labels") {
//     isEditDialogVisible.value = true;
//   }
//   toggleSidebar();
// };
// const handleClickOutside = (event) => {
//   if (event && event.sidebarHandled) {
//     return;
//   }
//   if (
//     isSidebarOpen.value &&
//     !document.querySelector(".sidebar")?.contains(event.target)
//   ) {
//     toggleSidebar();
//   }
// };

// watch(isSidebarOpen, () => {
//   if (isSidebarOpen.value === true) {
//     document.addEventListener("click", handleClickOutside);
//   } else {
//     document.removeEventListener("click", handleClickOutside);
//   }
// });
const clickClose = () => {
  if (isMobile) {
    toggleSidebar();
  }
};
onMounted(fetchLabels);
</script>

<style scoped>
.sidebar {
  width: 250px;
  height: calc(100vh - 50px);
  background-color: var(--sidebar-back);
  color: var(--corp-text-color);
  padding: 1rem;
  padding-bottom: 60px;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  transition: ease-out left 0.3s;
  overflow: auto;
  z-index: 2;
}
.sidebar.opened {
  left: 0;
}
.sidebar.closed {
  left: -250px;
}
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  /* flex: 1; */
}
li {
  display: flex;
  align-items: center;
  margin: 1rem 0;
  cursor: pointer;
}
.sidebar li a {
  text-decoration: none;
  color: var(--nav-clr);
  font-weight: 700;
}
.sidebar li a:hover {
  color: var(--nav-clr-hover);
}
.sidebar li a.activeLink {
  color: var(--nav-clr-active);
}
li i {
  margin-right: 10px;
}
footer {
  margin-top: auto;
  padding-top: 1rem;
}
.edit-labels {
  display: flex;
  flex-direction: column;
  min-width: 250px;
}
.label-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
</style>
