<template>
  <div>
    <template v-if="user">
      <div class="flex gap-2 align-items-center">
        <div
          :style="{
            backgroundImage:
              user?.profilePicture ||
              `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.username?.charAt(
                0
              )}')`,
          }"
          alt="User Profile"
          class="profile-pic"
        />
        <div class="flex-1">
          <div>{{ user?.username }}</div>
          <!-- <div>{{ user?.email }}</div> -->
        </div>
      </div>
    </template>
    <template v-else>
      <div v-if="loading">Loading...</div>
      <div v-else>Not found</div>
    </template>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useUserStore } from "../hooks/useUserStore";
const props = defineProps({ id: String });
const user = ref();
const loading = ref();
const { fetchUserById } = useUserStore();

const load = async () => {
  loading.value = true;
  user.value = await fetchUserById(props.id);
  loading.value = false;
};
load();
</script>
