<template>
  <div class="icon">
    <Button
      :disabled="props.disabled"
      icon="pi pi-send"
      @click="toggleCollaboratorMenu"
      v-tooltip.bottom="!showCollaboratorMenu ? 'Share' : null"
      text
      aria-haspopup="true"
    />

    <Dialog
      modal
      v-model:visible="showCollaboratorMenu"
      :draggable="false"
      :closable="false"
      :dismissableMask="true"
      v-if="showCollaboratorMenu"
      class="collaborator-menu"
      :position="isMobile ? 'bottom' : 'center'"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      @touchmove="handleTouchMove"
    >
      <template #header>
        <span class="font-bold"> Share </span>
        <div class="flex-1" />
        <Button text icon="pi pi-times" @click="toggleCollaboratorMenu" />
      </template>
      <div v-if="!showQrCode && canEdit" class="p-fluid">
        <!-- Input row for user email and permissions -->
        <div class="field flex align-items-center">
          <AutoComplete
            v-model="selectedUser"
            :suggestions="userSuggestions"
            @focus="searchUsersChange"
            @input="searchUsersChange"
            placeholder="Search or add emails"
            optionLabel="name"
            class="w-full flex-1 border-noround-right"
            inputClass="w-full"
            emptyMessage=""
            completeOnFocus
            :forceSelection="!validateEmail(selectedUser)"
            dropdownMode="blank"
          >
            <template #option="slotProps">
              <UserAutoCompleteItem :user="slotProps.option" />
            </template>
          </AutoComplete>
          <Dropdown
            v-model="selectedPermission"
            :options="permissionsOptions"
            optionLabel="label"
            optionValue="value"
            class="w-4 border-noround-left"
            placeholder="Permission"
            appendTo="self"
          />
        </div>
        <Button
          label="Invite"
          severity="primary"
          class="mt-2 p-button-success flex-1 w-full"
          :disabled="submitting"
          @click="shareItemClick"
          v-if="
            selectedUser &&
            (typeof selectedUser !== 'string' || validateEmail(selectedUser)) &&
            selectedPermission
            // && (validateEmail(selectedUser) || validateEmail(selectedUser.email))
          "
        />
        <div v-if="sharedUsers?.length > 0" class="shared-users">
          <hr />
          <h4>Shared With</h4>
          <ul>
            <li
              v-for="user in sharedUsers"
              :key="user.id"
              class="flex justify-between align-items-center"
            >
              <div class="flex gap-2 align-items-center">
                <div
                  v-if="user.type === 'temporary'"
                  :style="{
                    backgroundImage: `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${getTemporaryCollaboratorDisplay(
                      user
                    )?.charAt(0)}')`,
                  }"
                  alt="User Profile"
                  class="profile-pic"
                />
                <div
                  v-else
                  :style="{
                    backgroundImage: `url('https://placehold.co/600x400/EEE/31343C?font=open-sans&text=${user?.email?.charAt(
                      0
                    )}')`,
                  }"
                  alt="User Profile"
                  class="profile-pic"
                />
                <div class="flex-1 text-left">
                  <template v-if="user.type === 'temporary'">
                    {{ getTemporaryCollaboratorDisplay(user) }}
                    <small
                      v-tooltip="
                        `This user is not registered and will not receive updates or notifications, but their personal share link allows them to see the latest changes.`
                      "
                      >(Guest)</small
                    >
                    <Button
                      text
                      label="copy share link"
                      size="small"
                      @click="copyUserShareLink(user)"
                  /></template>
                  <template v-else> {{ user.email }}</template>
                  <div class="w-full">
                    <Dropdown
                      v-if="userCanEdit"
                      :modelValue="user.role"
                      :options="permissionsOptions"
                      optionLabel="label"
                      optionValue="value"
                      class="existing-role"
                      placeholder="Permission"
                      appendTo="self"
                      @change="
                        (e) =>
                          updateUserPermission({
                            ...user,
                            role: e.value,
                          })
                      "
                    />
                  </div>
                </div>
              </div>

              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-text"
                @click="removeUserShare(user, $event)"
              />
            </li>
          </ul>
        </div>
        <div v-else>
          <h4 class="my-6 text-center">Not shared with anyone</h4>
        </div>
      </div>
      <!-- Conditional rendering: show either the share panel or the QR code panel -->
      <div v-if="!showQrCode">
        <div v-if="shareUrl > ''" class="">
          <!-- Share this document URL -->
          <!-- <div class="p-inputgroup flex">
            <input
              id="document-url"
              type="text"
              class="p-inputtext w-full"
              :value="shareUrlComplete"
              readonly
            />
            <Button icon="pi pi-copy" @click="copyDocumentUrl" />
          </div> -->
          <div class="actions">
            <small class="txt"><strong>View-only sharing</strong></small>
            <div class="flex gap-3 buttons">
              <div class="text-center">
                <Button
                  v-if="canShare"
                  rounded
                  icon="pi pi-share-alt"
                  class="p-button-primary"
                  @click="shareDocument"
                />
                <div class="text-xs mt-1">Share to</div>
              </div>
              <div class="text-center">
                <Button
                  v-if="canShare"
                  rounded
                  icon="pi pi-copy"
                  class="p-button-primary"
                  @click="copyDocumentUrl"
                />
                <div class="text-xs mt-1">Copy URL</div>
              </div>
              <div class="text-center">
                <Button rounded icon="pi pi-qrcode" @click="showQrCodePanel" />
                <div class="text-xs mt-1">QR code</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- QR Code Panel -->
      <div v-if="showQrCode" class="qr-code-panel">
        <VueQrcode value="https://storatic.com/register" class="qr-code" />
        <Button
          label="Done"
          icon="pi pi-check"
          class="w-full mt-3"
          @click="hideQrCodePanel"
        />
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, toRefs, watch, nextTick } from "vue";
import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // Import QR Code component
import {
  debounce,
  validateEmail,
  isMobile,
  getTemporaryCollaboratorDisplay,
} from "../../utils/helpers";
import { useConfirm } from "primevue/useconfirm";
import { useCollaboratorStore } from "../../hooks/useCollaboratorStore";
import UserAutoCompleteItem from "./UserAutoCompleteItem.vue";
import { useSwipeHandler } from "../../hooks/useSwipe";

// Emits definition
const emit = defineEmits(["opened", "closed", "change"]);

const confirm = useConfirm();
const showCollaboratorMenu = ref(false);
const submitting = ref(false);
const showQrCode = ref(false); // State to toggle between share URL and QR code view
const toast = useToast();

const toggleCollaboratorMenu = (e) => {
  if (e) {
    e.colHandled = true;
  }
  showCollaboratorMenu.value = !showCollaboratorMenu.value;
};

const props = defineProps({
  itemType: String,
  noteId: [Number, String],
  shareUrl: String,
  ownerId: Number, // Owner of the item
  canEdit: Boolean,
});

const { handleTouchStart, handleTouchEnd, handleTouchMove } = useSwipeHandler({
  direction: "down",
  feedbackElement: `.collaborator-menu`,
  scrollContainerSelector: `.shared-users`,
  onSwipeDown: () => {
    showCollaboratorMenu.value = false;
  },
});

const { noteId, shareUrl } = toRefs(props);
const {
  fetchUserSuggestions,
  sharedUsers,
  fetchSharedUsers,
  removeSharedUser,
  addSharedUser,
  updateSharedUser,
} = useCollaboratorStore(noteId.value);

const selectedUser = ref(null);
const selectedPermission = ref("view");
const userSuggestions = ref([]);
const permissionsOptions = ref([
  { label: "Viewer", value: "view" },
  { label: "Editor", value: "edit" },
]);
const userCanEdit = ref(true); // Add logic to determine if the current user can edit

const searchUsersChange = debounce(async (e) => {
  const results = await fetchUserSuggestions(e?.target?.value);
  userSuggestions.value = results;
}, 300);

// Check if the Web Share API is available
const canShare = ref(!!navigator.share);

const shareItemClick = async (event) => {
  if (event) {
    event.stopPropagation();
    event.colHandled;
  }

  if (selectedUser.value && selectedPermission.value) {
    const accept = async () => {
      submitting.value = true;
      await addSharedUser(noteId.value, {
        user: selectedUser.value,
        role: selectedPermission.value,
      });

      selectedUser.value = null;
      selectedPermission.value = "view";
      await fetchSharedUsers(noteId.value);
      submitting.value = false;
    };
    if (selectedUser.value.type === "contact") {
      confirmContact(accept, () => {
        selectedUser.value = null;
        selectedPermission.value = "view";
      });
    } else {
      accept();
    }
  }
};

const removeUserShare = async (user, event) => {
  if (event) {
    event.stopPropagation();
    event.colHandled;
  }
  await removeSharedUser(noteId.value, user.id);
};

const updateUserPermission = async (user, event) => {
  if (event) {
    event.colHandled;
  }
  await updateSharedUser(noteId.value, user.id, { role: user.role });
};

const shareUrlComplete = `https://storatic.com/note/${noteId.value}/${shareUrl.value}`;
const shareUserUrlBase = `http://127.0.0.1:8080/note/${noteId.value}/`;

// Share document using the Web Share API
const shareDocument = async () => {
  if (navigator.share) {
    try {
      await navigator.share({
        title: "Shared a note",
        url: shareUrlComplete,
      });
      console.log("Document shared successfully!");
    } catch (err) {
      console.error("Error sharing the document:", err);
    }
  } else {
    console.error("Web Share API not supported on this browser.");
  }
};

const toastCopied = (
  message = "Public view only url was copied successfully"
) => {
  const defaultToast = {
    severity: "info",
    summary: "Copied to clipboard",
    detail: message,
    life: 3000,
  };
  toast.add({ ...defaultToast });
};
const copyUserShareLink = (user) => {
  console.log("shareUserUrlBase", user, shareUserUrlBase);
  navigator.clipboard.writeText(`${shareUserUrlBase}${user.shareToken}`);
  toastCopied(
    `${getTemporaryCollaboratorDisplay(
      user
    )} personal share url was copied successfully`
  );
};
const copyDocumentUrl = () => {
  navigator.clipboard.writeText(shareUrlComplete);
  toastCopied();
};

// Handle clicks outside the popover to close it
// const handleClickOutside = (event) => {
//   if (!event || event.colHandled) {
//     return;
//   }
//   if (!document.querySelector(".collaborator-menu")?.contains(event.target)) {
//     toggleCollaboratorMenu();
//   }
// };

// Show the QR code panel
const showQrCodePanel = (e) => {
  e.stopPropagation();
  showQrCode.value = true;
};

// Hide the QR code panel and go back to the original view
const hideQrCodePanel = () => {
  showQrCode.value = false;
};

watch(showCollaboratorMenu, () => {
  if (showCollaboratorMenu.value) {
    const fn = async () => {
      await fetchSharedUsers(noteId.value);
    };
    nextTick(() => {
      emit("opened");
      // document.addEventListener("click", handleClickOutside);
    });
    fn();
  } else {
    emit("closed");
    showQrCode.value = false;
    // document.removeEventListener("click", handleClickOutside);
  }
});

watch(
  sharedUsers,
  () => {
    emit("change", sharedUsers.value);
  },
  { deep: true }
);

const confirmContact = (accept, reject) => {
  confirm.require({
    message: `<p>This contact is not associated to a register user.</p>
    <p>Adding this contact to this note will prefill an SMS with a personal share link and message for you to review and send.</p>
    <p class='font-bold'>Note: This contact will not receive updates, but they can use their personal share URL at any time to view any changes.</p>
    <p>Would you like to continue?</p>`,
    header: "Confirm Collaborator",
    // icon: "pi pi-user",
    rejectProps: {
      label: "Cancel",
      severity: "secondary",
      outlined: true,
    },
    acceptProps: {
      label: "Yes, Continue",
    },
    accept,
    reject,
  });
};
</script>

<style>
.border-noround-right .p-inputtext {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.icon {
  position: relative;
}

.collaborator-menu {
  background: var(--flyout-back) !important;
  color: var(--corp-text-color) !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px;
  z-index: 1000;
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  max-height: 60vh;
  overflow-y: auto;
}
.collaborator-menu .actions {
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  padding: 0.5rem 0.5rem;
  margin-top: 2rem;
}
.collaborator-menu .actions .txt {
  display: block;
  padding-bottom: 0.5rem;
  font-size: 0.7rem;
}
.collaborator-menu .actions .buttons {
  padding: 0 0.5rem;
}
.p-dialog-content .collaborator-menu {
  top: auto;
  bottom: 0;
}
.collaborator-menu .p-select-label {
  padding: 0.5rem;
  text-align: left;
}
.collaborator-menu input {
  padding: 0.5rem 0.375rem;
}
.collaborator-menu .p-select-dropdown {
  width: 20px;
}
.collaborator-menu .p-select-dropdown .p-icon {
  width: 10px;
  height: 10px;
}
.collaborator-menu .existing-role {
  background: transparent;
  border: 0;
}
.collaborator-menu .existing-role .p-select-label {
  padding: 0;
}
</style>

<style scoped>
.shared-users {
  margin-top: 20px;
}

.shared-users ul {
  list-style: none;
  padding: 0;
}

.shared-users li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.shared-user-avatar,
.autocomplete-user-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.qr-code-panel {
  text-align: center;
}
.qr-code {
  margin: 20px auto;
}
</style>
