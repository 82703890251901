import { computed, ref } from "vue";
import store from "../store";
import api from "../utils/api";

export function useFileStore() {
  const isLoading = ref(false);
  const files = computed(() => store.state.files);

  async function fetchFiles(query) {
    try {
      isLoading.value = true;
      const response = await api.get("/api/files", { params: query });
      store.mutations.setState("files", response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    } finally {
      isLoading.value = false;
    }
  }

  async function createFile(fileData) {
    try {
      const response = await api.post("/api/files", fileData);
      store.mutations.addModel("files", response.data);
      return response.data;
    } catch (error) {
      console.error("Error creating file:", error);
      throw error;
    }
  }

  async function updateFile(id, fileData) {
    try {
      const response = await api.put(`/api/files/${id}`, fileData);
      store.mutations.updateModel("files", id, response.data);
      return response.data;
    } catch (error) {
      console.error("Error updating file:", error);
      throw error;
    }
  }

  async function deleteFile(id) {
    try {
      await api.delete(`/api/files/${id}`);
      // store.mutations.removeModel("files", id);
      for (const note of store.state.notes) {
        if (note.files && note.files.find((f) => f.id === id)) {
          store.mutations.updateModel("notes", note.id, {
            ...note,
            files: note.files.filter((f) => f.id !== id),
          });
        }
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      throw error;
    }
  }
  async function getFileEditUrl(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}/edit`);
      // store.mutations.addModel("files", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function fetchFileById(fileId) {
    try {
      const response = await api.get(`/api/files/${fileId}`);
      store.mutations.addModel("files", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching file by id:", error);
      throw error;
    }
  }

  async function searchFiles(query) {
    try {
      const response = await api.get("/api/files/search", { params: query });
      return response.data;
    } catch (error) {
      console.error("Error searching files:", error);
      throw error;
    }
  }

  async function createNoteFile(fileData, noteId) {
    try {
      const response = await api.post(`/api/notes/${noteId}/files`, {
        files: fileData,
      });
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        store.mutations.updateModel("notes", noteId, {
          ...note,
          files: [...note.files, ...response.data],
        });
      }
      return response.data;
    } catch (error) {
      console.error("Error creating file:", error);
      throw error;
    }
  }

  async function deleteNoteFile(id, noteId) {
    try {
      await api.delete(`/api/notes/${noteId}/files/${id}`);
      const note = store.state.notes?.find((note) => note.id === noteId);
      if (note) {
        store.mutations.updateModel("notes", noteId, {
          ...note,
          files: [...note.files.filter((f) => f.id !== id)],
        });
      }
    } catch (error) {
      console.error("Error deleting label:", error);
      throw error;
    }
  }

  return {
    files,
    isLoading,
    fetchFiles,
    createFile,
    updateFile,
    deleteFile,
    getFileEditUrl,
    fetchFileById,
    searchFiles,
    createNoteFile,
    deleteNoteFile,
  };
}
