<template>
  <div class="icon">
    <!-- <i class="pi pi-bell" @click="togglePopover" /> -->
    <Button
      :disabled="props.disabled"
      icon="pi pi-bell"
      @click="togglePopover"
      v-tooltip.bottom="showPopover ? null : 'Reminders'"
      text
      severity="contrast"
      aria-haspopup="true"
    />
    <Dialog
      v-if="showPopover"
      modal
      visible
      :draggable="false"
      :closable="false"
      class="reminder-menu"
      :position="isMobile ? 'bottom' : 'center'"
      @touchstart="handleTouchStart"
      @touchend="handleTouchEnd"
      @touchmove="handleTouchMove"
    >
      <!-- Custom Popover -->
      <template #header>
        <span class="font-bold">Reminders</span>
        <div class="flex-1" />
        <Button text icon="pi pi-times" @click="togglePopover" />
      </template>

      <div v-if="currentPanel === 'main'" class="quick-opts">
        <p @click="selectQuickOption('today')">Later today</p>
        <p @click="selectQuickOption('tomorrow')">Tomorrow</p>
        <p @click="selectQuickOption('nextWeek')">Next week</p>
        <Divider />
        <p @click="showPanel('date')">Pick date & time</p>
        <p @click="showPanel('location')">Pick place</p>
      </div>

      <!-- Date/Time Picker Panel -->
      <DateTimePickerPanel
        v-if="currentPanel === 'date'"
        :selectedDate="selectedDate"
        :selectedTime="selectedTime"
        :repeatFrequency="repeatFrequency"
        :frequency="frequency"
        :interval="interval"
        :endCondition="endCondition"
        :endAfterOccurrences="endAfterOccurrences"
        :endOnDate="endOnDate"
        :timeOptions="timeOptions"
        :repeatOptions="repeatOptions"
        @update:selectedDate="updateSelectedDate"
        @update:selectedTime="updateSelectedTime"
        @update:repeatFrequency="updateRepeatFrequency"
        @update:frequency="updateFrequency"
        @update:interval="updateInterval"
        @clearRepeat="clearRepeat"
        @showPanel="showPanel"
        @save="saveDateTime"
      />

      <LocationPickerPanel
        v-if="currentPanel === 'location'"
        :selectedLocation="selectedLocation"
        :locationTrigger="locationTriggerType"
        :filteredLocations="filteredLocations"
        @update:selectedLocation="updateSelectedLocation"
        @update:locationTrigger="updateLocationTrigger"
        @showPanel="showPanel"
        @save="setLocation"
      />

      <RepeatPanel
        v-if="currentPanel === 'repeat'"
        :frequency="frequency"
        :interval="interval"
        :endCondition="endCondition"
        :endAfterOccurrences="endAfterOccurrences"
        :endOnDate="endOnDate"
        :repeatUnitOptions="repeatUnitOptions"
        :repeatSummary="repeatSummary"
        @update:frequency="updateFrequency"
        @update:interval="updateInterval"
        @update:endCondition="updateEndCondition"
        @update:endAfterOccurrences="updateEndAfterOccurrences"
        @update:endOnDate="updateEndOnDate"
        @save="saveCustomRepeat"
        @showPanel="showPanel"
      />
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, nextTick, defineProps, defineEmits } from "vue";
import {
  addHours,
  addDays,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  format,
  isAfter,
} from "date-fns";
import Divider from "primevue/divider";
import Button from "primevue/button";

import Dialog from "primevue/dialog";
import DateTimePickerPanel from "./DateTimePickerPanel.vue";
import LocationPickerPanel from "./LocationPickerPanel.vue";
import RepeatPanel from "./RepeatPanel.vue";
import { useReminderStore } from "../../hooks/useReminderStore";
import { isMobile } from "../../utils/helpers";
import { useSwipeHandler } from "../../hooks/useSwipe";

const { createReminder, updateReminder } = useReminderStore();
// Props definition
const props = defineProps({
  selectedDate: {
    type: Date,
    // default: () => new Date(),
  },
  selectedTime: {
    type: String,
    default: null,
  },
  frequency: {
    type: Number,
    default: 0, // No repetition by default
  },
  interval: {
    type: Number,
    default: 1,
  },
  endCondition: {
    type: String,
    default: "never",
  },
  endAfterOccurrences: {
    type: Number,
    default: 1,
  },
  endOnDate: {
    type: Date,
    default: () => new Date(),
  },
  selectedLocation: {
    type: Object,
    default: null,
  },
  locationTriggerType: {
    type: String,
    default: "arrive",
  },
  noteId: String,
  id: String,
});

// Emits definition
const emit = defineEmits(["opened", "closed", "change"]);

// Reactive references
const showPopover = ref(false);
const currentPanel = ref(
  props.selectedDate ? "date" : props.selectedLocation ? "location" : "main"
); // Tracks the currently visible panel

const { handleTouchStart, handleTouchEnd, handleTouchMove } = useSwipeHandler({
  direction: "down",
  feedbackElement: `.reminder-menu`,
  scrollContainerSelector: `.shared-users`,
  onSwipeDown: () => {
    showPopover.value = false;
  },
});
// Time options
const timeOptions = [
  { label: "Morning (8:00 AM)", value: "8:00 AM" },
  { label: "Afternoon (1:00 PM)", value: "1:00 PM" },
  { label: "Evening (6:00 PM)", value: "6:00 PM" },
  { label: "Night (8:00 PM)", value: "8:00 PM" },
  // { label: "Custom", value: "custom" },
];

// Repeat options
const repeatOptions = [
  { label: "Does not repeat", value: 0 },
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 3 },
  { label: "Yearly", value: 4 },
  { label: "Custom", value: "custom" },
];

const repeatUnitOptions = [
  { label: "Daily", value: 1 },
  { label: "Weekly", value: 2 },
  { label: "Monthly", value: 3 },
  { label: "Yearly", value: 4 },
];

// Other references
const frequency = ref(props.frequency);
const interval = ref(props.interval);
const endCondition = ref(props.endCondition);
const endAfterOccurrences = ref(props.endAfterOccurrences);
const endOnDate = ref(props.endOnDate);
const filteredLocations = ref([]);
const repeatSummary = ref("");

const selectedDate = ref(props.selectedDate || new Date());
const selectedTime = ref(props.selectedTime || getRoundedTime());
const repeatFrequency = ref(props.initialRepeatFrequency);
const selectedLocation = ref(props.selectedLocation);
const locationTriggerType = ref(props.locationTriggerType);

// Computed properties
// const computedRepeatFrequency = computed(() => {
//   return repeatFrequency.value || (props.frequency ? "custom" : 0);
// });

// Function to toggle the popover
const togglePopover = (e) => {
  if (e) {
    e.stopPropagation();
  }
  if (showPopover.value) {
    closePopover();
  } else {
    showPopover.value = true;
    emit("opened");
  }
};

// Function to close the popover
const closePopover = () => {
  showPopover.value = false;
  currentPanel.value = "main";
  emit("closed");
};

// Function to show a specific panel
const showPanel = (panel) => {
  console.log("showPanel", panel);
  currentPanel.value = panel;
};

const selectQuickOption = (option) => {
  console.log(`Selected quick option: ${option}`);

  const now = new Date();

  if (option === "today") {
    const adjustedTime = addHours(now, 3);

    // Check if the adjusted time is still today or should move to tomorrow
    if (isAfter(adjustedTime, startOfTomorrow())) {
      // If the adjusted time is after today, set it to tomorrow at 09:00 AM
      selectedDate.value = startOfTomorrow();
      selectedTime.value = "09:00 AM";
    } else {
      // Otherwise, use the adjusted time today
      selectedDate.value = startOfToday();
      selectedTime.value = format(adjustedTime, "hh:mm a");
    }
  } else if (option === "tomorrow") {
    selectedDate.value = startOfTomorrow(); // Start of tomorrow
    selectedTime.value = "09:00 AM"; // Default time for tomorrow (e.g., 9:00 AM)
  } else if (option === "nextWeek") {
    selectedDate.value = startOfWeek(addDays(now, 7)); // Same day next week (assuming week starts on Monday)
    selectedTime.value = "09:00 AM"; // Default time for next week (e.g., 9:00 AM)
  }
  saveDateTime();
};

// Update functions
const updateSelectedDate = (newDate) => (selectedDate.value = newDate);
const updateSelectedTime = (newTime) => (selectedTime.value = newTime);
const updateRepeatFrequency = (newFrequency) =>
  (repeatFrequency.value = newFrequency);
const updateSelectedLocation = (newLocation) =>
  (selectedLocation.value = newLocation);
const updateLocationTrigger = (newTrigger) =>
  (locationTriggerType.value = newTrigger);
const updateFrequency = (newFrequency) => (frequency.value = newFrequency);
const updateInterval = (newInterval) => (interval.value = newInterval);
const updateEndCondition = (newEndCondition) =>
  (endCondition.value = newEndCondition);
const updateEndAfterOccurrences = (newEndAfterOccurrences) =>
  (endAfterOccurrences.value = newEndAfterOccurrences);
const updateEndOnDate = (newEndOnDate) => (endOnDate.value = newEndOnDate);

// Function to set the location
const setLocation = () => {
  console.log(
    `Location set: ${selectedLocation.value}, Trigger: ${locationTriggerType.value}`
  );

  // Now `dateObj` is a full Date object combining the date and time
  const model = {
    noteId: props.noteId,
    locationTriggerType: locationTriggerType.value,
    address: selectedLocation.value?.display_name,
  };

  if (selectedLocation.value.saved) {
    model.locationId = selectedLocation.value?.id;
  } else {
    model.lat = selectedLocation.value?.lat;
    model.lon = selectedLocation.value?.lon;
  }

  if (props.noteId) {
    persist(model);
  } else {
    emit("change", [{ locationId: "tmp", location: model, ...model }]);
  }
  closePopover();
};

// Function to save custom repeat settings
const saveCustomRepeat = () => {
  showPanel("date");
};

// Function to clear repeat settings
const clearRepeat = () => {
  frequency.value = 0;
  interval.value = null;
  endCondition.value = null;
  endAfterOccurrences.value = null;
  endOnDate.value = null;
};

// Utility function to get rounded time
function getRoundedTime() {
  const now = new Date();
  now.setMinutes(now.getMinutes() + 30);
  now.setMinutes(30 * Math.floor(now.getMinutes() / 30));
  return now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
}

// Handle clicks outside the popover to close it
const handleClickOutside = (event) => {
  if (!event || currentPanel.value !== "main") {
    return;
  }
  if (
    !document.querySelector(".reminder-menu")?.contains(event.target) &&
    !document.querySelector(".p-autocomplete-overlay")?.contains(event.target)
  ) {
    closePopover();
  }
};

watch(showPopover, () => {
  if (showPopover.value === true) {
    nextTick(() => {
      document.addEventListener("click", handleClickOutside);
    });
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
const saveDateTime = () => {
  const datePart = selectedDate.value; // This should be a Date object or a string like 'yyyy/MM/dd'
  const timePart = selectedTime.value; // This should be a time string like '8:00 AM'

  // Convert the date part to a Date object if it's not already
  let dateObj;
  if (typeof datePart === "string") {
    dateObj = new Date(datePart);
  } else {
    dateObj = new Date(datePart.getTime());
  }

  // Extract the hours and minutes from the time string
  const [time, modifier] = timePart.split(" ");
  let [hours, minutes] = time.split(":").map(Number);

  if (modifier === "PM" && hours !== 12) {
    hours += 12;
  } else if (modifier === "AM" && hours === 12) {
    hours = 0;
  }

  // Set the hours and minutes on the date object
  dateObj.setHours(hours);
  dateObj.setMinutes(minutes);
  dateObj.setSeconds(0); // Optional: Set seconds to 0

  // Now `dateObj` is a full Date object combining the date and time
  const model = {
    noteId: props.noteId,
    startTime: dateObj?.toISOString(),
    frequency: frequency.value,
    interval: interval.value,
    count: endCondition.value === "after" ? endAfterOccurrences.value : null,
    repeatEnd: endCondition.value === "on" ? endOnDate.value : null,
  };

  if (props.noteId) {
    persist(model);
  } else {
    emit("change", [{ event: model }]);
  }
  closePopover();
};

const persist = async (model) => {
  if (props.id) {
    const updatedMod = await updateReminder(props.id, model);
    emit("change", updatedMod);
  } else {
    const newMod = await createReminder(model);
    emit("change", newMod);
  }
};
</script>

<style>
.icon {
  position: relative;
}

.reminder-menu {
  /* position: absolute; */
  /* top: -50px; */
  background: var(--flyout-back) !important;
  color: var(--corp-text-color) !important;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1000;
  width: 400px;
  max-width: 98%;
  padding: 10px;
}
.p-dialog-content .reminder-menu {
  top: auto;
  bottom: 0;
}
.reminder-menu > p {
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  margin: 0 0 10px;
  cursor: pointer;
}
.quick-opts p {
  cursor: pointer;
}
.sub-panel {
  background: var(--flyout-back);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 0.5rem;
  width: 100%;
}

.sub-panel-title {
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-top: 0;
}

.split-button-toggle {
  display: flex;
  gap: 8px;
}

/* .split-button-toggle .p-button-primary {
  background-color: var(--primary-color);
}

.split-button-toggle .p-button:not(.p-button-primary) {
  background-color: var(--secondary-color);
} */

.flex {
  display: flex;
}

.gap-1 {
  gap: 8px;
}

.mt-2 {
  margin-top: 16px;
}
</style>
