<template>
  <Dialog
    v-model:visible="showModal"
    header="Activty"
    :modal="true"
    @update:visible="
      (v) => {
        if (!v) close();
      }
    "
    class="activity-modal"
    :closable="true"
    :draggable="false"
    :position="isMobile ? 'bottom' : 'center'"
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd"
  >
    <div v-if="isLoading" class="text-center">
      <ProgressSpinner />
    </div>

    <template v-else>
      <!-- activity List -->
      <div class="activities-container">
        <ul class="activities-list" v-if="activities?.length > 0">
          <li
            v-for="activity in activities"
            :key="activity.id"
            class="activity"
          >
            <div class="flex gap-2 align-items-start">
              <img
                :src="getUserProfilePicture(activity.userId)"
                class="activity-avatar"
              />
              <div class="activity-body flex-1 m-0">
                <span class="activity-user-name">
                  {{ getUserName(activity.userId) }}
                </span>
                <span
                  v-if="activity.action === 'update'"
                  class="body align-items-start"
                >
                  updated
                  <span
                    v-for="(detail, key, idx) in activity.details"
                    :key="idx"
                  >
                    <span>{{ formatKey(key) }}</span>
                    from
                    <span v-html="detail.oldValue || 'nothing'"></span>
                    to
                    <span v-html="detail.newValue || 'nothing'"></span>
                    <span v-if="idx < Object.keys(activity.details).length - 1"
                      >,
                    </span> </span
                  >.
                </span>
                <span
                  v-else-if="activity.action === 'create'"
                  class="body align-items-start"
                >
                  created a new
                  <span>{{ activity.modelType }}</span>
                  <!-- with
                  <span
                    v-for="(detail, key, idx) in activity.details"
                    :key="idx"
                  >
                    <span>{{ formatKey(key) }}</span
                    >:
                    <span v-html="detail.newValue || 'nothing'"></span>
                    <span v-if="idx < Object.keys(activity.details).length - 1"
                      >,
                    </span> </span
                  >.-->
                </span>
                <span
                  v-if="activity.action === 'add'"
                  class="body align-items-start"
                >
                  added {{ formatKey(activity?.model?.type) }}
                  <span
                    v-if="
                      activity.details.Task &&
                      activity.details.Task.model?.description
                    "
                    >{{ activity.details.Task.model?.description }}</span
                  >
                  <img
                    class="activity-img"
                    v-if="activity?.details?.Files"
                    :src="
                      activity.model.previewSignedUrl ||
                      activity.model.signedUrl
                    "
                  />

                  <span v-if="activity?.details?.Reminders">{{
                    activity?.model?.name
                  }}</span>

                  <span v-if="activity?.details?.Labels">{{
                    activity?.model.name
                  }}</span>
                </span>
                <span
                  v-if="activity.action === 'remove'"
                  class="body align-items-start"
                >
                  removed {{ formatKey(activity?.model?.type) }}
                  <span
                    v-if="
                      activity.details.Task &&
                      activity.details.Task.model?.description
                    "
                    >{{ activity.details.Task.model?.description }}</span
                  >
                  <span v-if="activity?.details?.Task">{{
                    activity?.details?.Task.description
                  }}</span>
                  <span v-if="activity?.details?.Files">{{
                    activity?.model
                  }}</span>
                  <span v-if="activity?.details?.Reminders">{{
                    activity?.model?.name
                  }}</span>
                  <span v-if="activity?.details?.Labels">{{
                    activity?.model.name
                  }}</span>
                </span>
                <span
                  v-if="activity.action === 'modify'"
                  class="body align-items-start"
                >
                  updated {{ formatKey(Object.keys(activity.details)[0]) }}
                  <span
                    v-if="
                      activity.details.Task &&
                      activity.details.Task.model?.description
                    "
                    >{{ activity.details.Task.model?.description }}</span
                  ><span
                    v-for="(detail, key, idx) in activity.details[
                      Object.keys(activity.details)[0]
                    ]"
                    :key="idx"
                    :class="{ hidden: key === 'id' || key === 'model' }"
                  >
                    <template v-if="key !== 'id' && key !== 'model'"
                      >{{ key }}
                      <span
                        v-html="
                          detail?.oldValue ||
                          (detail?.oldValue === false ? 'false' : 'nothing')
                        "
                      ></span>
                      to
                      <span
                        v-html="
                          detail?.newValue ||
                          (detail?.newValue === false ? 'false' : 'nothing')
                        "
                      ></span>
                      <span
                        v-if="
                          idx <
                          Object.keys(
                            activity.details[Object.keys(activity.details)[0]]
                          ).length -
                            1
                        "
                        >,
                      </span>
                    </template>
                  </span>
                </span>
                <!-- Timestamp -->
                <div class="activity-action">
                  <small class="text-muted">{{
                    formatDate(activity.timestamp)
                  }}</small>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <p class="text-center" v-else>No activty found.</p>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useActivityStore } from "../../hooks/useActivityStore";
import { useUserStore } from "../../hooks/useUserStore";
// import { useAuthStore } from "../../hooks/useAuthStore";
import { safeBackRoute, isMobile } from "../../utils/helpers";
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import { useSwipeHandler } from "../../hooks/useSwipe";
// import Button from "primevue/button";

const props = defineProps({ noteId: String });
const router = useRouter();
const route = useRoute();

const { fetchUserById, getUserName, getUserProfilePicture } = useUserStore();
const { activities, isLoading, fetchactivities } = useActivityStore();

const showModal = ref(true);

const { handleTouchStart, handleTouchEnd, handleTouchMove } = useSwipeHandler({
  direction: "down",
  onSwipeDown: () => {
    showModal.value = false;
    close();
  },
  feedbackElement: `.activity-modal`,
  scrollContainerSelector: `.activities-container`,
});

// Fetch activities on component mount
onMounted(() => {
  fetchactivities({ noteId: props.noteId });
});

watch(activities, () => {
  activities?.value?.forEach((activity) => fetchUserById(activity.userId));
});

const formatKey = (key) => {
  // Make the keys user-friendly
  switch (key) {
    case "content":
      return "content";
    case "color":
      return "color";
    case "shareUrl":
      return "share URL";
    case "title":
      return "title";
    case "userId":
      return "user ID";
    case "Task":
      return "task";
    case "Files":
      return "file";
    case "Labels":
      return "label";
    default:
      return key;
  }
};
const formatDate = (date) => {
  const now = new Date();
  const daysDiff = differenceInDays(now, date);
  const hoursDiff = differenceInHours(now, date);
  const minutesDiff = differenceInMinutes(now, date);

  if (daysDiff > 0) {
    return `${daysDiff}d`;
  } else if (hoursDiff > 0) {
    return `${hoursDiff}h`;
  } else {
    return `${minutesDiff}m`;
  }
};
const close = () => {
  const currentQuery = route.query;
  const path = safeBackRoute();
  router.push({ path, query: currentQuery });
};
</script>

<style>
.activity-actions {
  margin-left: 46px;
}
.activity-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  float: left;
}
.activity-user-name {
  font-weight: 600;
  margin-right: 4px;
}
.activity-modal {
  width: 600px;
  max-width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);
  border-radius: 10px !important;
}
.activity-modal .p-dialog-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.activity-user-name {
  margin: 0;
}
.activities-container {
  flex: 1;
  overflow-y: auto;
}

.activities-list {
  list-style-type: none;
  padding: 0;
}
.activities-list .body > * {
  display: inline-block;
  margin-right: 3px;
}

.activity {
  margin-bottom: 1.5rem;
}
.activity-img {
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-radius: 1rem;
  border: 1px solid #fff;
}
</style>
