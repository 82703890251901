<template>
  <div class="icon" v-tooltip.bottom="'More'">
    <Button icon="pi pi-ellipsis-h" text severity="contrast" @click="toggle" />
    <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import { useRouter } from "vue-router";
import { useNoteStore } from "../../hooks/useNoteStore";

const router = useRouter();

const { deleteNote } = useNoteStore();
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  noteId: String,
  isOwner: Boolean,
  canEdit: Boolean,
});
const showMoreOptionsMenu = ref(false);

const menu = ref();
const items = computed(() => {
  const baseItems = [
    {
      label: "Details",
      icon: "pi pi-info-circle",
      command: () => {
        router.push(`/note/${props.noteId}/details`);
      },
    },
    {
      label: "Activity",
      icon: "pi pi-list",
      command: () => {
        router.push(`/note/${props.noteId}/activity`);
      },
    },
    props.isOwner
      ? {
          label: "Comments",
          icon: "pi pi-chat",
          command: () => {
            router.push(`/note/${props.noteId}/comments`);
          },
        }
      : null,
    props.canEdit ? { separator: true } : null,
    props.canEdit
      ? {
          label: "Delete",
          icon: "pi pi-trash",
          class: "p-menu-item-danger",
          command: () => {
            deleteNote(props.noteId);
          },
        }
      : null,
  ];

  // Filter out null or undefined items
  return baseItems.filter((item) => item !== null);
});

const toggle = (event) => {
  menu.value.toggle(event);
};

const toggleMoreOptionsMenu = (e) => {
  if (e) {
    e.moreHandled = true;
  }
  showMoreOptionsMenu.value = !showMoreOptionsMenu.value;
};
const handleClickOutside = (event) => {
  console.log("handleClickOutside", event?.moreHandled);
  if (event && event.moreHandled) {
    return;
  }
  if (
    showMoreOptionsMenu.value &&
    !document.querySelector(".more-options-menu")?.contains(event.target)
  ) {
    toggleMoreOptionsMenu();
  }
};

watch(showMoreOptionsMenu, () => {
  if (showMoreOptionsMenu.value === true) {
    document.addEventListener("click", handleClickOutside);
  } else {
    document.removeEventListener("click", handleClickOutside);
  }
});
</script>

<style scoped>
.icon {
  position: relative;
}

.more-options-menu {
  position: absolute;
  background: var(--flyout-back);

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 10px 5px;
  z-index: 1000;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}
.more-options-menu button {
  flex: 1;
  cursor: pointer;
  /* color: var(--corp-text-color); */
  border: 0;
  /* padding: 0.5rem 0.75rem; */
  /* background: none; */
  white-space: nowrap;
  text-align: left;
  width: 100%;
  display: block;
}
</style>
